import { React, useEffect, useState,useContext } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { AuthContext } from "../context/AuthContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, ModalHeader,ModalFooter,ModalBody } from "reactstrap";
import config from '../config/configg';
import { TailSpin } from "react-loader-spinner";
import Moment from 'react-moment';
const BACKEND_URL = config.BACKEND_URL;

const NewsLetter = () => {
  const [deleteModal,setDeleteModal]=useState(false);
  const history = useNavigate();
 
  const [loading,setLoading]=useState(true);
  const [newData,setNewData]=useState();
  const [count, setCount] = useState(0);
  const [pages,setPage]=useState(1);
  const [currentPage,setCurrentPage]=useState(1);
  // const [page, setPage] = useState(0);
  const [sizePerPage,setSizePerPage]=useState(10);

  
  useEffect(() => {
    fetchAllBorhanUsers()
    // eslint-disable-next-line
  }, []);

  const fetchAllBorhanUsers = async () => {

     
   
    setLoading(true);
    const response = await fetch(`${BACKEND_URL}/website/getNewsLetter?limit=${sizePerPage}&page=${currentPage}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
     
    });
    const jsonnn = await response.json();
    console.log(jsonnn.data, "console the users");
    let data=jsonnn && jsonnn.data && jsonnn.data.newLetterData;
    console.log("this is response" , data)
    let count=jsonnn &&jsonnn.data && jsonnn.data.count;
    setNewData(data)
    setPage(
      parseInt(count % sizePerPage) == 0
        ? parseInt(count / sizePerPage)
        : parseInt(count / sizePerPage + 1)
    );
    setLoading(false)
  };
  const handlePageClick = (data) => {
    let current = data.selected + 1;
    console.log(current, "currentpage");
    setCurrentPage(current);
  };
  
  return (
    <>
      {loading ? (
          <div className="custm-loader">
            <TailSpin color="#000" height={200} width={200} />
          </div>
        ) : null}
         
      <section class="admin-wrapper">
    
        <div class="admin-content-wrapper">
          <div class="admin-title-header mt-0">
            <div class="row">
              <div class="col-lg-6">
                <div class="">
                  <div class="admin-title-flex">
                    <h3>New Letter</h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="admin-short-nav-buttons">
                  <div class="table-btn-group">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="admin-filter-navbar bg-grey">
            {/* <div class="row align-items-center justify-content-end">
              <div class="col-lg-3">
                <div class="filter-input-box">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search User"
                  />
                  <img src="./assets/img/search-icon.png" alt="" />
                </div>
              </div>
            </div> */}
          </div>
          <div class="row mt-4">
            <div class="col-lg-12">
              <div class="admin-white-box">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="common-table-wrapper mt-0 newletter-table">
                      <div class="table-responsive">
                      <table className="table">
                          <thead>
                            <tr>
                              <th>Email </th>
                              <th>Subscribed Date</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                            {
                              console.log("this is data" ,newData )
                            }
                            {newData &&
                              newData.length > 0 &&
                              newData.map((obj, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{obj.email}</td>
                                    <td><Moment format="DD-MM-YYYY hh:mm">{obj.subscribedAt}</Moment></td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                       {newData && newData.length>0?
                       <>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={pages}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination justify-content-center"
                          }
                          forcePage={currentPage - 1}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                        </>:"No Data"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer/>
      
    </>
  );
};

export default NewsLetter;
