import { React, useEffect, useState, useRef } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import config from '../config/configg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import {formatDate} from "../utils/helpers"
import html2PDF from 'jspdf-html2canvas';
import logoImage from '../assets/img/main-logo.png'

const BACKEND_URL = config.BACKEND_URL;


const SubscriptionHistory = () => {
    const [searchFirm, setSearchFirm] = useState('')
    const [searchEmail, setSearchEmail] = useState('')
    const [searchStatus, setSearchStatus] = useState('')
    const [showTable, setShowTable] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const tableRef = useRef(null)
    const [paginationData, setPaginationData] = useState({
        docs: [],
        hasNextPage: false,
        hasPrevPage: false,
        limit: 0,
        offset: 0,
        page: 0,
        pagingCounter: 0,
        totalDocs: 0,
        totalPages: 0,
    });

    const history = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("token")) {
            getSubscriptionHistory()
        } else {
            history("/login");
        }

    }, [searchFirm, currentPage, searchEmail,searchStatus]);

    const getSubscriptionHistory = async () => {

        const response = await fetch(
            `${BACKEND_URL}/admin/getSubscriptionHistory?page=${currentPage}&searchFirm=${searchFirm}&searchEmail=${searchEmail}&searchStatus=${searchStatus}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": localStorage.getItem("token"),
                }
            }
        );
        const jsonnn = await response.json();
        // console.log("jsonnn", jsonnn)
        if (jsonnn.statusCode === 200) {
            console.log(jsonnn.data)
            setPaginationData(jsonnn.data);
            // setFirmData(jsonnn.data.firms)
        } else {
            toast(jsonnn.message)
        }

    };
    function calculateTotalMonths(startDate, endDate) {
        startDate = new Date(startDate)
        endDate = new Date(endDate)
        const diffInMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 +
          endDate.getMonth() - startDate.getMonth();
      
        return diffInMonths;
      }

      useEffect(() => {
        if (showTable) {
          generatePDF()
          setShowTable(false)
        }
      }, [showTable])

    const handlePageClick = (data) => {
        let current = data.selected + 1;
        setCurrentPage(current)
    };

    const generatePDF = async () => {
        
        let page = document.getElementsByClassName('showNone');      
          const pdf = await html2PDF(page, {
            jsPDF: {
                format: 'a4',
            },
            imageType: 'image/jpeg',
            output: 'Subscription_history',
        });

    }


    return (
        <>
            <ToastContainer />
            <section className="admin-wrapper">
                <div className="admin-content-wrapper">
                    <div className="admin-title-header mt-0">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="">
                                    <div className="admin-title-flex">
                                        <h3>Subscription history</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg text-end">
                                <DownloadTableExcel
                                    filename={`Subscription_history`}
                                    sheet="Subscription_history"
                                    currentTableRef={tableRef.current}
                                >
                                    <button className="btn btn-blue"> Export excel </button>

                                </DownloadTableExcel>
                                <button className="btn btn-blue ms-2" onClick={e => setShowTable(true)} type="button">Export PDF</button>
                            </div>
                        </div>
                        <div className="admin-filter-navbar bg-grey">
                        <div className="row">
                            <div className="col-sm">
                                <div className="filter-input-box">
                                    <label className="col-lg" style={{width:'100%'}}>
                                        Firm Name
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search Firm Name"
                                            value={searchFirm}
                                            onChange={(e) => { setCurrentPage(1); setSearchFirm(e.target.value); }}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="filter-input-box">
                                    <label className="col-lg" style={{width:'100%'}}>
                                        Firm Email
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search Firm Email"
                                            value={searchEmail}
                                            onChange={(e) => { setCurrentPage(1); setSearchEmail(e.target.value); }}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="filter-input-box">
                                    <label className="col-lg" style={{width:'100%'}}>
                                        Status
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search Status"
                                            value={searchStatus}
                                            onChange={(e) => { setCurrentPage(1); setSearchStatus(e.target.value); }}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-lg-12">
                            <div className="common-table-wrapper mt-0 checkTable">
                                
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th> Firm Name </th>
                                                <th> Email </th>
                                                <th>Subscription Status</th>
                    
                                                <th>Start Date</th>
                                                <th>End Date </th>
                                                <th>Duration</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginationData.docs &&
                                                paginationData.docs.length > 0 &&
                                                paginationData.docs.map((el, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{el?.firmData?.firmName}</td>
                                                            <td>{el?.userData?.email}</td>
                                                            <td>{el?.status}</td>
                                                            
                                                            <td>{el?.startDate && formatDate(new Date(el?.startDate))}</td>
                                                            <td className="text-start">{el?.endDate && formatDate(new Date(el?.endDate))}</td>
                                                            <td>{calculateTotalMonths(el?.startDate,el?.endDate)} Months</td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="common-table-wrapper mt-0 checkTable showNone" ref={tableRef} style={{ position: 'absolute', display: showTable ? '' : 'none' }}>
                                <img src={logoImage} style={{display:"block", marginLeft:"auto",marginRight:"auto"}} alt="" />
                                <div className="table-responsive">
                                    <table className="table" ref={tableRef}>
                                        <thead>
                                            <tr>
                                                <th> Firm Name </th>
                                                <th> Email </th>
                                                <th>Subscription Status</th>
                    
                                                <th>Start Date</th>
                                                <th>End Date </th>
                                                <th>Duration</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginationData.docs &&
                                                paginationData.docs.length > 0 &&
                                                paginationData.docs.map((el, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{el?.firmData?.firmName}</td>
                                                            <td>{el?.userData?.email}</td>
                                                            <td>{el?.status}</td>
                                                            
                                                            <td>{el?.startDate && formatDate(new Date(el?.startDate))}</td>
                                                            <td className="text-start">{el?.endDate && formatDate(new Date(el?.endDate))}</td>
                                                            <td>{calculateTotalMonths(el?.startDate,el?.endDate)} Months</td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            </div>
                            {paginationData.docs && paginationData.docs.length > 0 ?
                                <div className="react-paginate-wrapper"><ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={paginationData?.totalPages}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={
                                        "pagination justify-content-center"
                                    }
                                    forcePage={paginationData?.page - 1}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                /></div>
                                : <div className="text-center">{"No Data"}</div>}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SubscriptionHistory;
