import { React, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import config from '../config/configg';
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TailSpin } from "react-loader-spinner";
import { Modal, ModalHeader,ModalFooter,ModalBody } from "reactstrap";

const BACKEND_URL = config.BACKEND_URL;

const ExpertRequest = () => {
  const [mainLoader,setMainLoader]=useState(true);
  const [experts, setExperts] = useState([]);
  const [approvedByAdmin, setApprovedByAdmin] = useState(null);
  const [dummy, setDummy] = useState(0);
  const [pages, setPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(5);
  const [deleteModal,setDeleteModal]=useState(false);
  const [acceptModal,setAcceptModal]=useState(false);
  const [clickedExpert,setClickedExpert]=useState();
  const history = useNavigate();
  const fetchAllExpertsRequests = async (current) => {
    setMainLoader(true);
    const response = await fetch(
      `${BACKEND_URL}/admin/getExpertsRequests`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          limit: sizePerPage,
          page: current,
        }),
      }
    );
    const jsonnn = await response.json();
    setMainLoader(false);
    console.log(jsonnn);
    if(jsonnn.statusCode===200)
    {}
    else{
        toast(jsonnn.message);
    }
    setExperts(jsonnn.data.list);
    setPages(
      parseInt(jsonnn.data.count % sizePerPage) == 0
        ? parseInt(jsonnn.data.count / sizePerPage)
        : parseInt(jsonnn.data.count / sizePerPage + 1)
    );
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchAllExpertsRequests(currentPage);
    } else {
      history("/login");
    }

    // eslint-disable-next-line
  }, []);
  const onClickApproved = async (obj) => {
    setMainLoader(true);
    const response = await fetch(
      `${BACKEND_URL}/admin/getExpertApprovedByAdmin/${obj._id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const jsonnn = await response.json();
    setMainLoader(false);
    setAcceptModal(false);
    if(jsonnn.statusCode===200)
    toast('Successfully approved');
    else{
      toast(jsonnn.message);
    }
    console.log(jsonnn);
    setDummy(1);
    fetchAllExpertsRequests(currentPage);
  };

  const onClickRejected = async (obj) => {
    setMainLoader(true);
    const response = await fetch(
      `${BACKEND_URL}/admin/getExpertRejectedByAdmin/${obj._id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const jsonnn = await response.json();
    setDeleteModal(false);
    
    setMainLoader(false);
    if(jsonnn.statusCode===200)
    {
      toast('Successfully deleted');
    }
    else{
       
    }
    console.log(jsonnn);
    setDummy(2);
    fetchAllExpertsRequests(currentPage);
  };

  const handlePageClick = (data) => {
    let current = data.selected + 1;
    console.log(current, "currentpage");
    setCurrentPage(current);
    fetchAllExpertsRequests(current);
  };
  return (
    <> <ToastContainer/>
    {mainLoader ? (
          <div className="custm-loader">
            <TailSpin color="#000" height={200} width={200} />
          </div>
        ) : null}
   

      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex">
                    <h3>Expert Request</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-short-nav-buttons">
                  <div className="table-btn-group">
                    <Link to="/expertlisting">
                      <button className="btn" type="button">
                        {" "}
                        <i className="fa fa-long-arrow-left me-2"></i> Back
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="admin-white-box">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="common-table-wrapper mt-0">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Name </th>
                              <th>Contact number</th>
                              <th>Email ID</th>
                              <th>Account Type</th>
                              <th>Uploaded doc</th>
                              <th> &nbsp; </th>
                            </tr>
                          </thead>
                          {/* <tbody>
                                    <tr>
                                       <td>
                                          <h5>Vaibhav Jagtap</h5>
                                       </td>
                                       <td>
                                          <h5>90908-09089</h5>
                                       </td>
                                       <td>
                                          <h5>Emailfirm@gmail.com</h5>
                                       </td>
                                       <td>
                                          <h6><Link to="javascript:;">Document.dox   </Link></h6>
                                       </td>
                                       <td>
                                          <div className="table-btn-group">
                                             <Link to="javascript:;"><button data-bs-target="#rejectExpertModal"  data-bs-dismiss="modal" data-bs-toggle="modal" className="btn" type="button">Reject</button></Link>
                                             <Link to="javascript:;"><button className="btn" type="button">Approve</button></Link>
                                          </div>
                                       </td>
                                    </tr>
                                  
                                 </tbody> */}
                          <tbody>
                            {console.log(experts, "itssexpertshere")}
                            {experts &&
                              experts.map((obj, index) => {
                                return (
                                  <tr>
                                    <td>
                                      <h5>
                                        {obj?.userId?.firstName}{" "}
                                        {obj?.userId?.lastName}
                                      </h5>
                                    </td>
                                    <td>
                                      <h5>{obj?.userId?.mobileNo}</h5>
                                    </td>
                                    <td>
                                      <h5>{obj?.userId?.email}</h5>
                                    </td>
                                    <td>
                                      <h5>{obj?.accountType}</h5>
                                    </td>
                                    <td>
                                      <h6>
                                        <Link to="javascript:;">
                                          {obj?.document[0]?.fileName}{" "}
                                        </Link>
                                      </h6>
                                    </td>

                                    <div className="table-btn-group">
                                      <button
                                        className="btn"
                                        type="button"
                                        onClick={() => {
                                          // setApprovedByAdmin(1);
                                          // onClickRejected(obj);
                                          setDeleteModal(true);
                                          setClickedExpert(obj);
                                        }}
                                      >
                                        Reject
                                      </button>
                                      <button
                                        className="btn"
                                        type="button"
                                        onClick={() => {
                                          // setApprovedByAdmin(2);
                                          // onClickApproved(obj);
                                          setClickedExpert(obj);
                                          setAcceptModal(true);
                                        }}
                                      >
                                        Approve
                                      </button>
                                    </div>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                        {experts.length >0 ?
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={pages}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination justify-content-center"
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                        :<div className="">No Data</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Reject expert modal --> */}
      <div
        className="modal fade"
        id="rejectExpertModal"
        tabindex="-1"
        aria-labelledby="rejectExpertModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close reject-req-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="reject-req-modal">
                <div>
                  <h3>Reject Expert Request </h3>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    className="form-control"
                    placeholder="Description . . . . . "
                  ></textarea>
                  <button className="btn" type="button">
                    Reject Request
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={deleteModal} toggle={() => setDeleteModal(false)} className='  modal-dialog modal-dialog-centered '>
      
  
      <ModalHeader>
       <h5 class="modal-title" id="exampleModalLabel">Confirm </h5>
       
        </ModalHeader>
      <ModalBody>
        Please Confirm to delete Expert Request
      </ModalBody>
      <ModalFooter>
        <button type="button" class="btn btn-secondary" onClick={()=>{setDeleteModal(false);}}>Close</button>
        <button type="button" class="btn btn-primary" onClick={()=>onClickRejected(clickedExpert)}>Delete Expert Request</button>
      </ModalFooter>
    
     
 </Modal>

 <Modal isOpen={acceptModal} toggle={() => setAcceptModal(false)} className='  modal-dialog modal-dialog-centered '>
      
  
      <ModalHeader>
       <h5 class="modal-title" id="exampleModalLabel">Confirm </h5>
       
        </ModalHeader>
      <ModalBody>
        Please Confirm to accept Expert Request
      </ModalBody>
      <ModalFooter>
        <button type="button" class="btn btn-secondary" onClick={()=>{setAcceptModal(false);}}>Close</button>
        <button type="button" class="btn btn-primary" onClick={()=>onClickApproved(clickedExpert)}>Accept Expert Request</button>
      </ModalFooter>
    
     
 </Modal>
    </>
  );
};

export default ExpertRequest;
