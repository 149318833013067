import { React, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import config from "../config/configg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const BACKEND_URL = config.BACKEND_URL;

const ManageSubscription = () => {
  const [subscriptionData, setSubscriptionData] = useState([]);
  const history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      getAllSubscriptionTypes();
    } else {
      history("/login");
    }
  }, []);

  const getAllSubscriptionTypes = async () => {
    const response = await fetch(
      `${BACKEND_URL}/admin/getSubscriptionTypeByAdmin`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );
    const jsonnn = await response.json();
    
    setSubscriptionData(jsonnn.data);
  };

  const deleteSubscriptionType = async (id) => {
    let dataToSend = { id: id };

    const response = await fetch(
      `${BACKEND_URL}/admin/deleteSubscriptionTypeByAdmin`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(dataToSend),
      }
    );
    const jsonnn = await response.json();

    if (jsonnn.statusCode !== 200) {
      toast.error(jsonnn.message);
    } else {
      getAllSubscriptionTypes();
      toast(jsonnn.message);
    }
  };

  const addSubscriptionType = async () => {
    if (subscriptionData.length >= 3) {
      console.log("jsonnn add subscription");
      toast("Max 3 Types Of Subscription Plan is allowed");
    } else {
      history("/addsubscription");
    }
  };

  return (
    <>
      <ToastContainer />
      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex">
                    <h3>Manage Subscription</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-short-nav-buttons">
                  <div className="table-btn-group">
                    <button
                      className="btn black-fill"
                      type="button"
                      onClick={() => {
                        addSubscriptionType();
                      }}
                    >
                      Add Subscription plan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="common-table-wrapper mt-0">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th> Subscription Name </th>
                        <th> Subscription For </th>
                        <th>Subscription Duration </th>

                        <th>Plan Amount</th>
                        <th>Wallet Amount </th>
                        <th>Discount Price</th>
                        <th> Actions </th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscriptionData &&
                        subscriptionData.length > 0 &&
                        subscriptionData.map((el, i) => {
                          return (
                            <tr key={i}>
                              <td>{el.planName}</td>
                              <td>{el.subscriptionFor}</td>
                              <td>{el.planDuration}</td>

                              <td>{el.planAmount}</td>
                              <td>{el.walletAmount}</td>
                              <td>{el.discountValue}</td>
                              <td>
                                <button
                                  className="btn"
                                  type="button"
                                  onClick={() => {
                                    history(`/editSubscription/${el._id}`);
                                  }}
                                >
                                  {" "}
                                  <img
                                    src="./assets/img/edit-pencil-icon.png"
                                    alt=""
                                  />{" "}
                                </button>
                                <button
                                  className="btn"
                                  type="button"
                                  onClick={() => {
                                    deleteSubscriptionType(el._id);
                                  }}
                                >
                                  {" "}
                                  <img
                                    src="./assets/img/delete-icon.png"
                                    alt=""
                                  />{" "}
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ManageSubscription;
