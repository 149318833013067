import { React, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from '../config/configg';
import ReactPaginate from "react-paginate";
import moment from "moment"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal } from 'react-bootstrap';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import html2PDF from 'jspdf-html2canvas';
import logoImage from '../assets/img/main-logo.png'
const BACKEND_URL = config.BACKEND_URL;


const Logs = () => {
  const [logs, setLogs] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [selectedLog, setSelectedLog] = useState([]);
  const tableRef = useRef(null)

  const [currentPage, setCurrentPage] = useState(1)
  const [searchParticular, setSearchParticular] = useState('')
  const [searchUpdatedBy, setSearchUpdatedBy] = useState('')
  const [showTable, setShowTable] = useState(false)

  const [paginationData, setPaginationData] = useState({
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 0,
    offset: 0,
    page: 0,
    pagingCounter: 0,
    totalDocs: 0,
    totalPages: 0,
  });

  const history = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getAllLogs()
    } else {
      history("/login");
    }

    // eslint-disable-next-line
  }, [currentPage, searchParticular, searchUpdatedBy]);


  const getChangesById = async (id) => {
    const response = await fetch(
      `${BACKEND_URL}/admin/getLogsById/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),


        }
      }
    );

    const jsonnn = await response.json();
    if (jsonnn.statusCode === 200) {
      setSelectedLog(jsonnn.data.rawData)
    } else {
      toast(jsonnn.message)
    }
  }

  const showLogDetails = async (log) => {
    await getChangesById(log)
    setShowModal(true);
  };

  const getAllLogs = async () => {

    const response = await fetch(
      `${BACKEND_URL}/admin/getLogs?page=${currentPage}&searchParticular=${searchParticular}&searchUpdatedBy=${searchUpdatedBy}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),


        }
      }
    );
    const jsonnn = await response.json();
    if (jsonnn.statusCode === 200) {
      setPaginationData(jsonnn.data)
      setLogs(jsonnn.data.docs) 
    } else {
      toast(jsonnn.message)
    }

  };
  const handlePageClick = (data) => {
    let current = data.selected + 1;
    setCurrentPage(current)
  };

  useEffect(() => {
    if (showTable) {
      generatePDF()
      setShowTable(false)
    }
  }, [showTable])

  const generatePDF = async () => {
    let page = document.getElementsByClassName('showNone');

    const pdf = await html2PDF(page, {
      jsPDF: {
        format: 'a4',
      },
      imageType: 'image/jpeg',
      output: 'Subscription_history',
    });
  }

  return (
    <>
      <ToastContainer />
      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">

            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex">
                    <h3>Logs</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-short-nav-buttons">
                  <div className="text-end">
                    <DownloadTableExcel
                      filename={`Logs`}
                      sheet="Logs"
                      currentTableRef={tableRef.current}
                    >
                      <button className="btn btn-primary"> Export excel </button>

                    </DownloadTableExcel>
                    <button className="btn btn-primary ms-2" onClick={e => setShowTable(true)} type="button">Export PDF</button>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="admin-filter-navbar bg-grey">
            <div className="row align-items-center">

              {/*hi  */}
              <div className="col-sm">
                <div className="filter-input-box">
                  <label style={{ width: '100%' }}>
                    Particular
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Particular"
                      value={searchParticular}
                      onChange={(e) => { setCurrentPage(1); setSearchParticular(e.target.value) }}
                    />
                  </label>
                </div>
              </div>
              <div className="col-sm">
                <div className="filter-input-box">
                  <label style={{ width: '100%' }}>
                    Updated By
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Updated By"
                      value={searchUpdatedBy}
                      onChange={(e) => { setCurrentPage(1); setSearchUpdatedBy(e.target.value) }}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="admin-white-box">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="common-table-wrapper mt-0">
                      <div className="table-responsive">
                        <table className="table">

                          <thead>
                            <tr>
                              <th> &nbsp; </th>
                              <th>Particular </th>
                              <th>Time </th>
                              <th>Type</th>
                              <th>Updated By</th>
                              <th>User Ip </th>
                              <th>Browser </th>
                              {/* <th>Action </th> */}
                              {/* <th> &nbsp; </th>
                              <th> &nbsp; </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {!logs || logs.length === 0 ?
                              // <h2 style={{position:"absolute",marginLeft:"390px"}}>No data</h2>
                              <div className="text-center">No Data</div>
                              : ""
                            }
                            {logs && logs.length > 0 && logs.map((el, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <img
                                      src="./assets/img/user-img.png"
                                      className="img img-fluid"
                                      alt=""
                                    />
                                  </td>

                                  <td>

                                    <h5>{el?.particular}</h5>
                                  </td>
                                  <td>
                                    <h5>{moment(el?.createdAt).format("DD MMMM YYYY, hh:mm A")}</h5>
                                  </td>
                                  <td>
                                    <h6>
                                      {el?.type}
                                    </h6>
                                  </td>
                                  <td>
                                    <h6>
                                      {el?.user.email}
                                    </h6>
                                  </td>
                                  <td>
                                    <h6>
                                      {el?.userIp}
                                    </h6>
                                  </td>
                                  <td>
                                    <h6>{el?.browser}</h6>
                                  </td>
                                  <td>

                                    {/* <button className="plain-button" onClick={() => showLogDetails(el?._id)}>
                                <img
                                        src="/assets/img/eye-icon.png"
                                        alt=""
                                      />
                                </button> */}
                                  </td>
                                  <td>
                                  </td>
                                </tr>)
                            })}

                          </tbody>
                        </table>
                      </div>
                      <div className="col-sm-12 common-table-wrapper mt-0 showNone" ref={tableRef} style={{ position: 'absolute', display: showTable ? '' : 'none' }}>
                        <div className="table-responsive">
                        <img src={logoImage} style={{display:"block", marginLeft:"auto",marginRight:"auto"}} alt="" />

                          <table className="table">

                            <thead>
                              <tr>
                                <th>Particular </th>
                                <th>Time </th>
                                <th>Type</th>
                                <th>Updated By</th>
                                <th>User Ip </th>
                                <th>Browser </th>
                              </tr>
                            </thead>
                            <tbody>
                              {!logs || logs.length === 0 ?
                                // <h2 style={{position:"absolute",marginLeft:"390px"}}>No data</h2>
                                <div className="text-center">No Data</div>
                                : ""
                              }
                              {logs && logs.length > 0 && logs.map((el, i) => {
                                return (
                                  <tr key={i}>

                                    <td>

                                      <h5>{el?.particular}</h5>
                                    </td>
                                    <td>
                                      <h5>{moment(el?.createdAt).format("DD MMMM YYYY, hh:mm A")}</h5>
                                    </td>
                                    <td>
                                      <h6>
                                        {el?.type}
                                      </h6>
                                    </td>
                                    <td>
                                      <h6>
                                        {el?.user.email}
                                      </h6>
                                    </td>
                                    <td>
                                      <h6>
                                        {el?.userIp}
                                      </h6>
                                    </td>
                                    <td>
                                      <h6>{el?.browser}</h6>
                                    </td>
                                  </tr>)
                              })}

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {paginationData.docs && paginationData.docs.length > 0 ?
                      <div className="react-paginate-wrapper"><ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={paginationData?.totalPages}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "pagination justify-content-center"
                        }
                        forcePage={paginationData?.page - 1}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      /></div>
                      : <div className="text-center">{"No Data"}</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Log Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {selectedLog.map((change, index) => (

            <div className="change-item" key={index}>
              <div className="change-details">
                <p className="change-field">Field: {change?.fieldName}</p>
                <p className="change-value">Old Value: {change?.oldValue}</p>
                <p className="change-value">New Value: {change?.newValue}</p>
              </div>
            </div>
          ))}
          {selectedLog.length === 0 && <p>No changes found.</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Logs;
