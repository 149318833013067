import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../config/configg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import appConstants from "../appConstants";
import { CustomSelect } from "./CustomSelect";
import axios from "axios";
const BACKEND_URL = config.BACKEND_URL;

const RecordPayment = () => {
    const [firmData,setFirmData] = useState([])
    const [selectedUser,setSelectedUser] = useState("")
    const [selectedFirm,setSelectedFirm] = useState("")
    const [plans,setPlans] = useState([])
    const [selectedPlan,setSelectedPlan] = useState("")
    const [allFirms,setAllFirms] = useState([])
    const [currentPlan,setCurrentPlan] = useState("")
  const history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("token")) {
        getAllFirms()
        
    } else {
      history("/login");
    }

    // eslint-disable-next-line
  }, []);

  const getAllFirms = async () => {

    const response = await fetch(
      `${BACKEND_URL}/firm/getAllFirms?page=1&limit=1000`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        }
      }
    );
    const jsonnn = await response.json();
    if (jsonnn.statusCode === 200) {
    //   setPaginationData(jsonnn.data)
    
    setAllFirms(jsonnn.data.docs)
    let firms = [];
    for (let firm of jsonnn.data.docs) {
        firms.push({
            value: firm._id,
            label: firm.firmName
            });
        }
        setFirmData(firms)
    } else {
      toast(jsonnn.message)
    }

  };

  const getPlans = async () => {

try{

    const result = await axios({
        method: "GET",
        url: `${config.BACKEND_URL}/common/getLegalSubscription`,
        headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token")
        },
    });
    // if (jsonnn.statusCode === 200) {
        //   setPaginationData(jsonnn.data)
        
        let plans = [];
        for (let plan of result?.data?.data) {
            plans.push({
                value: plan._id,
                label: plan.planName
            });
        }
        setPlans(plans)
            
}catch(err){
    toast.error(err)
}
}


const getSelectedPlan = async(userId)=>{
    const apiUrl = `${config.BACKEND_URL}/admin/checkSubscription?userId=${userId}`;
    const response = await axios.get(apiUrl);
    setCurrentPlan(response?.data?.currentSubscription?._id)
}



const handlePageReset = () =>{
  setSelectedUser("")
  setSelectedFirm("")
    setCurrentPlan("")
    setPlans([])
    // setAllFirms([])
    // setFirmData([])
    setSelectedPlan("")
    
}


const buySubscription =async()=>{

    if(selectedFirm===''){
        toast.error("Please select Firm")
        return
    }
    if(selectedPlan===''){
        toast.error("Please select Plan")
        return
    }



    try{

        const body={
            subscriptionId:selectedPlan,
            userId:selectedUser,
            firmId:selectedFirm
        }
        
        
        const response = await axios.post(`${config.BACKEND_URL}/admin/addFirmSubscriptionByAdmin`,
        body
        , {
            headers: {
                'Content-Type': 'application/json',
          'auth-token': localStorage.getItem('token'),
        },
    });
    if(response.data.statusCode===400){
        toast.error(response.message)
    }
    if(response.data.statusCode===200){
                toast(response.data.message);
                handlePageReset()
    }

}catch(err){
    console.log(err)
    toast.error(err)
}
    


}

useEffect(()=>{

    if(selectedFirm!==""){

        const singleFirm = allFirms.filter((firm)=>{
            return firm._id === selectedFirm
        })
        getPlans()
        setSelectedUser(singleFirm[0]?.user?._id)

        getSelectedPlan(singleFirm[0]?.user?._id)

    }
    
},[selectedFirm])

const handleFirmChange = async (event) => {
  setSelectedFirm(event);
  };
  const handlePlanChange = async (event) => {
    
    setSelectedPlan(event);
  };


  return (
    <>
      <ToastContainer />
      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-9">
                <div className="">
                  <div className="admin-title-flex">
                    <h3>Subscription Payment</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="basic-info-wrp">
                <div className="admin-white-box p-0">
                  <div className="basic-info-header">
                    <h5>Record Payment</h5>
                  </div>

                  <div className="form-feilds-container">
                    

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Firm</h3>
                        </div>
                            <div className="ms-1">
                            <div className="filter-select-box">
                              <div className="react-select">
                                <CustomSelect
                                  placeholder="Search Existing Firms"
                                  isClearable={true}
                                  onChange={handleFirmChange}
                                  value={selectedFirm}
                                  options={firmData}
                                  
                                />
                              </div>
                            </div>
                          </div>
                            
                      </div>
                    </div>

                    
                    
                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Plan</h3>
                        </div>
                        <div className="ms-1">
                            <div className="filter-select-box">
                              <div className="react-select">
                                <CustomSelect
                                  placeholder="Search Existing Plans"
                                  isClearable={true}
                                  onChange={handlePlanChange}
                                  value={selectedPlan}
                                  options={plans}
                                  isOptionDisabled={(option) =>
                                     currentPlan == option.value
                                  }
                                />
                              </div>
                            </div>
                          </div>
                            
                      </div>
                    </div>

                    
                  </div>
                  <div className="form-fields-row mb-5">
                    <div className="row">
                      <div className="col-lg-3"></div>
                      <div className="col-lg-3">
                        <button
                          className="btn btn-grey-common"
                          type="submit"
                          onClick={() =>
                             buySubscription()
                            }
                        >
                          Purchase Plan
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RecordPayment;
