const role = {
  admin: "admin",
  expert: "expert",
  borhanuser: "borhanuser",
};
const activityStatus = {
  active: "Active",
  busy: "Busy",
  unavailable: "Unavailable",
};
const accountType = {
  freelancer: "freelancer",
  expert: "expert",
};

const appointmentType = {
  audio: "audio",
  video: "video",
};

const appointmentStatus = {
  cancelled: "cancelled",
  confirmed: "confirmed",
  pending: "pending",
};

const chatRoomStatus = {
  cancelled: "cancelled",
  confirmed: "confirmed",
  pending: "pending",
};

const subscriptionTimings = {
  oneMonth: { value: 1, display: "1 Month" },
  twoMonths: { value: 2, display: "2 Months" },
  threeMonths: { value: 3, display: "3 Months" },
  fourMonths: { value: 4, display: "4 Months" },
  fiveMonths: { value: 5, display: "5 Months" },
  sixMonths: { value: 6, display: "6 Months" },
  sevenMonths: { value: 7, display: "7 Months" },
  eightMonths: { value: 8, display: "8 Months" },
  nineMonths: { value: 9, display: "9 Months" },
  tenMonths: { value: 10, display: "10 Months" },
  elevenMonths: { value: 11, display: "11 Months" },
  twelveMonths: { value: 12, display: "1 Year" },
};

export default {
  role,
  activityStatus,
  accountType,
  appointmentType,
  appointmentStatus,
  chatRoomStatus,
  subscriptionTimings,
};
