const settings = {
  // BACKEND_URL: "http://localhost:5000",
  BACKEND_URL:"https://api.borhans.com",

  // BACKEND_URL: 'http://192.168.1.4:4044  ',

  // dev
};

export default settings;
