import { React, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import config from '../config/configg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, ModalHeader,ModalFooter,ModalBody } from "reactstrap";
import { TailSpin } from "react-loader-spinner";

const BACKEND_URL = config.BACKEND_URL;

const AddCategories = () => {
  const [categories, setCategories] = useState({
    name:'',
    description:'',
  });
  const [image, setImage] = useState("");
  const [documentKey,setDocumentKey]=useState('');
  const [loading,setLoading]=useState(false)
  const history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("token")) {
    } else {
      history("/login");
    }

    // eslint-disable-next-line
  }, []);
  const onSubmitCreateCategories = async () => {
    setLoading(true)
    if(categories.name.length<2)
    {
      toast('Please Enter a valid category name');
      return;
    }
    else if (categories.description<1)
    {
      toast('Description must not be empty');
      return;
    }
    const response = await fetch(`${BACKEND_URL}/admin/addCategories`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),

        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        name: categories.name,
        description: categories.description,
        original: image,
      }),
    });
    const json = await response.json();
     if(json.statusCode===200)
     {
       toast('Category added successfully');
       setCategories({
        name:'',
        description:'',
      });
      setImage("");
      setDocumentKey("changed");
      setDocumentKey('');
      setLoading(false)
      history(`/managecategories`)
     }
     else{
       toast(json.message);
       setLoading(false)
     }
    // console.log(json, "addpractice");
  };
  const uploadFilesUsingMulter = async (e, i) => {
    // setLoading(true)
    // console.log("iiiihiihiohiin", "aloha0", e.target.files[0]);
    var formData = new FormData();
    formData.append("files", e.target.files[0]);
    const axiosRes = await axios({
      method: "post",
      url: `${BACKEND_URL}/admin/uploadfile`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
    // setLoading(false)

    // console.log(i, "idkkkkk", axiosRes);
    if (i === 1) {
      setImage(axiosRes.data.data[0].path);
    }
  };
  const onChangeCategories = (e) => {
    setCategories({
      ...categories,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
    <ToastContainer/>
    {loading ? (
          <div className="custm-loader">
            <TailSpin color="#000" height={200} width={200} />
          </div>
        ) : null}
      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex">
                    <h3>Add new Category</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-short-nav-buttons">
                  <div className="table-btn-group">
                    <Link to="/managecategories">
                      <button className="btn" type="button">
                        {" "}
                        <i className="fa fa-long-arrow-left me-2"></i> Back
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="basic-info-wrp">
                <div className="admin-white-box p-0 pt-4">
                  <div className="form-feilds-container">
                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Category Name</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <input
                              type="text"
                              name="name"
                              onChange={onChangeCategories}
                              className="form-control"
                              placeholder="Category name"
                              value={categories?.name}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Description</h3>
                        </div>
                        <div className="col-lg-5">
                          <div className="form-group">
                            <textarea
                              name="description"
                              onChange={onChangeCategories}
                              id=""
                              cols="30"
                              rows="5"
                              className="form-control"
                              placeholder="Write here.."
                              value={categories.description}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Upload Thumbnail icon</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <input
                              type="file"
                              accept="image/png,image/jpeg,image/jpg"
                              onChange={(e) => {
                                uploadFilesUsingMulter(e, 1);
                              }}
                              name="upload"
                              className="form-control"
                              placeholder="Attach Document"
                              key={documentKey}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row mb-5">
                      <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3">
                          <button
                            className="btn btn-grey-common"
                            type="submit"
                            onClick={onSubmitCreateCategories}
                          >
                            Add Category
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddCategories;
