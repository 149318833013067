import { React, useEffect } from "react";
import { useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useJwt } from "react-jwt";
import { AuthContext } from "../context/AuthContext";
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios"
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { TailSpin } from "react-loader-spinner";


import { auth } from '../utils/firebase';

import 'react-toastify/dist/ReactToastify.css';
import { Modal, ModalHeader,ModalFooter,ModalBody } from "reactstrap";

import config from '../config/configg';
const BACKEND_URL = config.BACKEND_URL;

const Login = () => {
  
  //   const { decodedToken, isExpired } = useJwt(localStorage.getItem("token"));
  const {isLoggedIn,setIsLoggedIn,loggedInAdminInfo,setLoggedInAdminInfo}=useContext(AuthContext);


  const [user] = useAuthState(auth);
  const [loading,setLoading] = useState(false)
  const [loginMode, setLoginMode] = useState("email");
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otpSent, setSentOtp] = useState(null)
  const [otp,setOtp]= useState('')
  const [confirmationResult, setConfirmationResult] = useState(null);

  
  const history = useNavigate();
  const [loginCredential, setLoginCredential] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
if(isLoggedIn)
{
  history('/managefirm');
}
  }, [isLoggedIn]);

//   const validateJwt = async () => {
//    console.log('decoded',decodedToken);
//     const response = await fetch(`${BACKEND_URL}/admin/getUserDetails`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         // 'Content-Type': 'application/x-www-form-urlencoded',
//       },
//       body: JSON.stringify({
//         mobileNo: decodedToken?.mobileNo,
//       }),
//     });
//     const jsonnn = await response.json();
//     if(jsonnn.error==='Please authenticate using valid token')
//     localStorage.removeItem('token')
//     console.log(jsonnn,"jsonn of validate token ");
//     if (jsonnn.data.role === "admin") {
//     } else {
//       console.log("user is not valid");
//       localStorage.removeItem("token");
//       history("/login");
//     }
//     //  setGetPracticeArea(jsonnn.data);
//   };
  
const onSubmitLogin = async () => {
  if(loginCredential.email.length<5)
  {
    toast('Enter a valid email');
    return;
  }
  else if(loginCredential.password.length<1)
  {
    toast('Enter a valid password');
    return;
  }
    const response = await fetch(`${BACKEND_URL}/admin/adminLogin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        email: loginCredential.email,
        password: loginCredential.password,
      }),
    });
    const json = await response.json();
    if (json.statusCode === 200) {
      localStorage.setItem("token", json?.data?.token);
      localStorage.setItem("user", JSON.stringify(json?.data?.user));
      setIsLoggedIn(true);
      toast('Login successful');
      history("/managefirm");
    }
    else {
      toast(json.message);
    }
  };

  const onChangeLogin = (e) => {
    setLoginCredential({ ...loginCredential, [e.target.name]: e.target.value });
  };




//   const onSubmitLoginPhn = async () => {

//     try {

//         if(otpSent){
//             setLoading(true);
//   const verified= await confirmationResult.confirm(otp);
//   const inputTag = document.getElementById("otp")
//   inputTag.value = ''
  
//   const payload= {
//       mob:phoneNumber,
//       hash:verified.user._delegate.accessToken,
//       otp:otp
//     }

//     const loggedRes = await axios.post(`${BACKEND_URL}/admin/adminloginMob`, payload)

 
//     localStorage.setItem("token", loggedRes.data);
//     setIsLoggedIn(true);
//     toast('Login successful');
//     history("/home");
        

//         setLoading(false);
//         // toast(t("Login successful"));
        

//         }else{
//             const inputTag = document.getElementById("phoneNumber")
//             setLoading(true);
//             const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
//                 size: 'invisible'
//             });
//             inputTag.value = ''
//         const confirmation = await firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier);
//         setConfirmationResult(confirmation);
//         setLoading(false);
       
//         setSentOtp(true)
//         console.log(confirmation, 'OTP sent successfully');
//     }
// } catch (error) {
//     setLoading(false);
//    const msg = error.code.split('/')
//     // toast(t(error.message));
//     // toast(t(msg[1]))
//     setTimeout(()=>{
//         window.location.reload('false')
//     },3000)
    
//         console.error(error);
//     }
// }



  return (
    <>
    <ToastContainer/>
    {loading ? (
          <div className="custm-loader">
            <TailSpin color="#000" height={200} width={200} />
          </div>
        ) : null}
    <div id="recaptcha-container"></div>
      <section class="login-page">
        <div class="container-fluid">
          <div class="row border">
            <div class="col-lg-6 p-0">
              <div class="auth-modal-artwork">
                <img
                  src="./assets/img/human-right-artwok.png"
                  class="img img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div class="col-lg-6 p-0">
              <div class="text-center p-5">
                <div class="login-form w-100">
                  <div class="auth-modal-logo">
                    <img
                      src="./assets/img/logo-login.png"
                      class="img img-fluid logo-login"
                      alt=""
                    />
                  </div>
                  <h5 class="mt-3">Please Login in your Account</h5>
                  <div class="">
                    {loginMode ==='email' ?(

                    

                    <div class=" mt-5 ">
                      <label
                        for="email"
                        class="form-label d-flex justify-content-start"
                      >
                        Username:
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        name="email"
                        onChange={onChangeLogin}
                        placeholder="Enter your Username"
                        value={loginCredential.email}
                      />
                    </div>
                    ):(
                    <div className=" mt-5 ">
                    <label
                        htmlFor="phoneNumber"
                        className="form-label d-flex justify-content-start"
                    >
                        {otpSent?"OTP:": "Phone Number:"}
                    </label>
                    <input
                        type={otpSent?'number':"tel"} 
                        className="form-control"
                        id={otpSent?"otp":"phoneNumber"}
                        name={otpSent?"otp":"phoneNumber"}
                        onChange={otpSent?(e) => setOtp(e.target.value):(e) => setPhoneNumber(e.target.value)}
                        placeholder={otpSent?"Otp":"Enter your Phone Number"}
                        
                    />
                </div>
            )
                    }
                    {loginMode==="email" && (

                    <div class="mb-3 mt-3">
                      <label
                        for="pwd"
                        class="form-label d-flex justify-content-start"
                      >
                        Password:
                      </label>
                      <input
                        type="password"
                        class="form-control"
                        id="pwd"
                        name="password"
                        onChange={onChangeLogin}
                        value={loginCredential.password}
                        placeholder="Enter your password"
                      />
                    </div>
                    )}

                    {/* Toggle */}
                    {/* <div className="form-check form-switch d-flex justify-content-start py-3">
                                                
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="loginToggle"
                                                    onChange={() =>
                                                        setLoginMode(loginMode === "email" ? "phone" : "email")
                                                    }
                                                />
                                                <label className="form-check-label" htmlFor="loginToggle">
                                                    {loginMode === "email"
                                                        ?"Use Phone Number":"Use Email"}
                                                        
                                                </label>
                                            </div> */}

{
  loginMode==='email'?
  <button
                      type="submit"
                      class="btn w-100 mt-4 login-btn "
                      onClick={onSubmitLogin}
                      >
                      Login
                    </button>:
                //      <button
                //      type="submit"
                //      className="btn w-100 mt-4 login-btn"
                //      onClick={onSubmitLoginPhn}
                //  >
                //      {otpSent? "Login" : "Send OTP"}
                //  </button>
                    ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
};

export default Login;
