import { React, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import config from '../config/configg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, ModalHeader,ModalFooter,ModalBody } from "reactstrap";
import { TailSpin } from "react-loader-spinner";
const BACKEND_URL = config.BACKEND_URL;

const EditPracticeArea = () => {
  const [image, setImage] = useState("");
  const history = useNavigate();
  const { practiceId } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [getCategories, setGetCategories] = useState([]);
  const [loading,setLoading]=useState(false)

  const [practiceAreaData, setPracticeAreaData] = useState({
    name: "",
    description: "",
    createdBy: "",
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchPracticeAreaDetails();
      fetchAllCategories();
    } else {
      history("/login");
    }

    // eslint-disable-next-line
  }, []);
  const fetchPracticeAreaDetails = async (obj) => {
    setLoading(true)
    const response = await fetch(
      `${BACKEND_URL}/admin/getPracticeAreaDetails/${practiceId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),

          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const jsonnn = await response.json();
    // console.log(jsonnn);
    setLoading(false)
    setImage(jsonnn.data.url.original);
    setSelectedCategory(jsonnn.data.categoryId);
    setPracticeAreaData({
      name: jsonnn.data.name,
      description: jsonnn.data.description,
      createdBy: jsonnn.data.createdBy,
    });
  };
  const fetchAllCategories = async () => {
    const response = await fetch(
      `${BACKEND_URL}/admin/getCategoriesData`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const jsonnn = await response.json();
    console.log("satyamtomar", jsonnn);
    setGetCategories(jsonnn.data);
    // console.log("sdflkjsdfkjlda", getCategories);
  };
  const onSubmitEditPracticeArea = async () => {
    setLoading(true)
    if(practiceAreaData.name.length<2)
    {
      toast('please enter a valid practice area name');
      return;
    }
    else if(practiceAreaData.description<1)
    {
      toast('description must not be empty');
      return;
    }
    const response = await fetch(
      `${BACKEND_URL}/admin/editPracticeArea/${practiceId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),

          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          name: practiceAreaData.name,
          description: practiceAreaData.description,
          original: image,
          createdBy: practiceAreaData.createdBy,
          categoryId: selectedCategory,
        }),
      }
    );
    const json = await response.json();
    if(json.statusCode===200)
    {
      toast('Practice Area has been edited successfully');
      setLoading(false)
      history("/managepractice");
    }
    else{
      toast(json.message);
      setLoading(false)
    }
    console.log(json, "addpractice");
  };
  const uploadFilesUsingMulter = async (e, i) => {
    // console.log("iiiihiihiohiin", "aloha0", e.target.files[0]);
    var formData = new FormData();
    formData.append("files", e.target.files[0]);
    const axiosRes = await axios({
      method: "post",
      url: `${BACKEND_URL}/admin/uploadfile`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
    console.log(i, "idkkkkk", axiosRes);
    if (i === 1) {
      setImage(axiosRes.data.data[0].path);
    }
  };
  const onChangePracticeArea = (e) => {
    setPracticeAreaData({
      ...practiceAreaData,
      [e.target.name]: e.target.value,
    });
  };
  const handleNewImageUpload = () => {
    setImage("");
  };
  return (
    <>
    {loading ? (
          <div className="custm-loader">
            <TailSpin color="#000" height={200} width={200} />
          </div>
        ) : null}
    <ToastContainer/>
      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex">
                    <h3>Edit Practice Area</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-short-nav-buttons">
                  <div className="table-btn-group">
                    <Link to="/managecategories">
                      <button className="btn" type="button">
                        {" "}
                        <i className="fa fa-long-arrow-left me-2"></i> Back
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="basic-info-wrp">
                <div className="admin-white-box p-0 pt-4">
                  <div className="form-feilds-container">
                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Practice Area Name</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <input
                              type="text"
                              name="name"
                              onChange={onChangePracticeArea}
                              value={practiceAreaData?.name}
                              className="form-control"
                              placeholder="Practice area name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Category</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <select
                              className="form-select"
                              onChange={(e) => {
                                setSelectedCategory(e.target.value);
                              }}
                              value={selectedCategory}
                            >
                              {getCategories &&
                                getCategories.map((obj, index) => {
                                  return (
                                    <option value={`${obj._id}`}>
                                     {obj.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        {/* <div className="col-lg-3">
                          <Link to="javascript:;">Add new Category</Link>
                        </div> */}
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Description</h3>
                        </div>
                        <div className="col-lg-5">
                          <div className="form-group">
                            <textarea
                              name="description"
                              onChange={onChangePracticeArea}
                              value={practiceAreaData?.description}
                              id=""
                              cols="30"
                              rows="5"
                              className="form-control"
                              placeholder="Write here.."
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Upload Thumbnail icon</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            {console.log("this is image" , image)}
                            {image ? (
                              <span>
                                <img
                                    src={`${image}`}
                                    alt=""
                                  />
                                {/* {image} */}
                                <button
                                  className="btn"
                                  type="button"
                                  onClick={handleNewImageUpload}
                                >
                                  {" "}
                                  <img
                                    src="/assets/img/delete-icon1.png"
                                    alt=""
                                  />{" "}
                                </button>
                              </span>
                            ) : (
                              <input
                                type="file"
                                onChange={(e) => {
                                  uploadFilesUsingMulter(e, 1);
                                }}
                                name="upload"
                                className="form-control"
                                placeholder="Attach Document"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row mb-5">
                      <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3">
                          <button
                            className="btn btn-grey-common"
                            type="submit"
                            onClick={onSubmitEditPracticeArea}
                          >
                            Edit Practice Area
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditPracticeArea;
