import { React, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import config from '../config/configg';
import { Modal, ModalHeader,ModalFooter,ModalBody } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TailSpin } from "react-loader-spinner";

const BACKEND_URL = config.BACKEND_URL;

const ExpertListing = () => {
  const [experts, setExperts] = useState([]);
  const [expertsDataUnAltered, setExpertsDataUnAltered] = useState([]);
  const [filterType, setFilterType] = useState('1');
  const [pages, setPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(5);
  const [searchedTerm, setSearchedTerm] = useState("");
  const [deleteModal,setDeleteModal]=useState(false);
  const [expertToBeDeleted,setExpertToBeDeleted]=useState([]);
  const [loading,setLoading]=useState(true);
  const history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchAllExperts(currentPage, searchedTerm,filterType);
    } else {
      history("/login");
    }

    // eslint-disable-next-line
  }, [searchedTerm,filterType]);
  const fetchAllExperts = async (current, search,filterType) => {
    setLoading(true);
    // console.log(search, "search mai hai kya?");
    const response = await fetch(`${BACKEND_URL}/admin/getExperts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        limit: sizePerPage,
        page: current,
        search: search,
        filterType:filterType,
      }),
    });
    const jsonnn = await response.json();
    console.log(jsonnn.data.list, "console the experts");
    setExperts(jsonnn.data.list);
    setExpertsDataUnAltered(jsonnn.data.list);
    setPages(
      parseInt(jsonnn.data.count % sizePerPage) == 0
        ? parseInt(jsonnn.data.count / sizePerPage)
        : parseInt(jsonnn.data.count / sizePerPage + 1)
    );
    setLoading(false);
  };

  const handlePageClick = (data) => {
    let current = data.selected + 1;
    console.log(current, "currentpage");
    setCurrentPage(current);
    fetchAllExperts(current, searchedTerm,filterType);
  };
  const handleDeleteExpert = async (obj) => {
    const response = await fetch(
      `${BACKEND_URL}/admin/deleteExpertByAdmin/${obj._id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const jsonnn = await response.json();
    if(jsonnn.statusCode==200){
    setDeleteModal(false);
    toast('Expert successfully deleted');
    }
    else{
      toast(jsonnn.message);
    }
     fetchAllExperts(currentPage, searchedTerm,filterType);
    
  };

  
  return (
    <>
    <ToastContainer/>
    {loading ? (
          <div className="custm-loader">
            <TailSpin color="#000" height={200} width={200} />
          </div>
        ) : null}
         

      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex mx-2">
                    <h3>Manage Expert</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-short-nav-buttons">
                  <div className="table-btn-group">
                    <Link to="/addnewexpert">
                      <button className="btn black-fill" type="button">
                        Add New Expert
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-between">
            <div className="col-lg-6">
              <div className="admin-filter-navbar">
                <div className="row align-items-center">
                  <div className="col-lg-4">
                    <div className="filter-input-box">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Expert"
                        onChange={(e) => {
                          setSearchedTerm(e.target.value);
                         
                        }}
                      />
                      <img src="./assets/img/search-icon.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6  "
              style={{ paddingTop: "25px", width: "25%" }}
            >
              <select
                className="form-select"
                onChange={(e) => {
                  setFilterType(e.target.value);
                  
                }}
                value={filterType}
              >
                 <option value={'1'}>All</option>
                <option value={'2'}>Expert</option>
                <option value={'3'}>Freelancer</option>
              </select>
            </div>
          </div>
          {/* <div className="text-center mt-4">
          <button type="button" className="btn btn-dark " onClick={()=>{onClickShowExperts(1);setCurrentPage(1) ;setFilterType(1)}}>Expert</button>
                <button type="button" className="btn btn-dark mx-3" onClick={()=>{onClickShowFreelancers(1); setCurrentPage(1);setFilterType(2);}}>Freelancer</button>

          </div> */}
          <div> </div>
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="admin-white-box">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="common-table-wrapper mt-0">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Name </th>
                              <th>Practice area </th>
                              {/* <th>Purchased Plan</th> */}
                              <th>Contact number</th>
                              <th>Email ID </th>
                              <th>Account Type</th>
                              <th> &nbsp; </th>
                            </tr>
                          </thead>
                          <tbody>
                            {experts &&
                              experts.map((obj, index) => {
                                return (
                                  <tr>
                                    <td>
                                      <h5>
                                        {obj?.userId?.firstName}{" "}
                                        {obj?.userId?.lastName}
                                      </h5>
                                    </td>
                                    <td>
                                      <h5>{obj?.practiceArea[0]?.name}</h5>
                                    </td>
                                    {/* <td>
                                      <div className="black-badge">
                                        Basic Plan
                                      </div>
                                    </td> */}
                                    <td>
                                      <h5>{obj?.userId?.mobileNo}</h5>
                                    </td>
                                    <td>
                                      <h6>{obj?.userId?.email}</h6>
                                    </td>
                                    <td>
                                      <h6>{obj?.accountType}</h6>
                                    </td>
                                    <div className="action-btn-group">
                                      {/* <Link to="javascript:;"><button className="btn" type="button"> <img src="./assets/img/eye-icon-black.png" alt="" /> </button></Link> */}
                                      <Link
                                        to={`/expertlisting/editexpert/${obj._id}`}
                                      >
                                        <button className="btn" type="button">
                                          {" "}
                                          <img
                                            src="./assets/img/edit-pencil-icon.png"
                                            alt=""
                                          />{" "}
                                        </button>
                                      </Link>
                                      <a>
                                        <button
                                          className="btn"
                                          type="button"
                                          onClick={() =>{
                                            // handleDeleteExpert(obj)
                                            setExpertToBeDeleted(obj);
                                            setDeleteModal(true);
                                          }
                                          }
                                        >
                                          {" "}
                                          <img
                                            src="./assets/img/delete-icon.png"
                                            alt=""
                                          />{" "}
                                        </button>
                                      </a>
                                    </div>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                        {experts.length>0 ?
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={pages}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination justify-content-center"
                          }
                          forcePage={currentPage - 1}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                        :<div className="text-center">No Data</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal isOpen={deleteModal} toggle={() => setDeleteModal(false)} className='  modal-dialog modal-dialog-centered '>
      
  
      <ModalHeader>
       <h5 class="modal-title" id="exampleModalLabel">Confirm </h5>
       
        </ModalHeader>
      <ModalBody>
        Please Confirm to Delete Expert
      </ModalBody>
      <ModalFooter>
        <button type="button" class="btn btn-secondary" onClick={()=>{setDeleteModal(false);}}>Close</button>
        <button type="button" class="btn btn-primary" onClick={()=>handleDeleteExpert(expertToBeDeleted)}>Delete Expert</button>
      </ModalFooter>
    
     
 </Modal>
    </>
  );
};

export default ExpertListing;
