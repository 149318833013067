import { React, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import QandAnsActions from "../actions/QandAns.actions";
import { TailSpin } from "react-loader-spinner";
const EditQndA = () => {
    const { id } = useParams();

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [loading,setLoading]=useState(false)

  const history = useNavigate();
 
  useEffect(() => {
    if (localStorage.getItem("token")) {

    } else {
      history("/login");
    }
    fetch()
 

    // eslint-disable-next-line
  }, []);
  const fetch=()=>{
    setLoading(true)
    let payload={
        id:id
    }
    console.log(payload);
    QandAnsActions.getQuesAndAnsById(payload,(err,res)=>{
        if(err){
          setLoading(false)

        }else{
            console.log(res.data);
            let data=res.data;
            setQuestion(data.question);
            setAnswer(data.answer);
          setLoading(false)

        }
    })
  }
  const onSubmitCreatePracticeArea = async () => {
    let payload={
        question:question,
        answer:answer,
        id:id
    }
    if(!answer&&!question){
      alert("fill both")  
      return
    }
  QandAnsActions.editQuesAndAns(payload,(err,res)=>{
      if(err){

      }else{
          console.log(res);
          // alert("update Q and solution")
          history("/QandAns");
      }
  })
  };
 


  return (
    <>
    {loading ? (
          <div className="custm-loader">
            <TailSpin color="#000" height={200} width={200} />
          </div>
        ) : null}
      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex">
                    <h3>Edit  Frequently Questions</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-short-nav-buttons">
                  <div className="table-btn-group">
                    <Link to="/QandAns">
                      <button className="btn" type="button">
                        {" "}
                        <i className="fa fa-long-arrow-left me-2"></i> Back
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="basic-info-wrp">
                <div className="admin-white-box p-0 pt-4">
                  <div className="form-feilds-container">
                  <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Question</h3>
                        </div>
                        <div className="col-lg-5">
                          <div className="form-group">
                            <textarea
                              name="description"
                              value={question}
                              onChange={e=>{
                                setQuestion(e.target.value)
                              }}
                              id=""
                              cols="30"
                              rows="5"
                              className="form-control"
                              placeholder="Write here.."
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                 

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Answer</h3>
                        </div>
                        <div className="col-lg-5">
                          <div className="form-group">
                            <textarea
                              name="description"
                              value={answer}
                              onChange={e=>{
                                  setAnswer(e.target.value)
                              }}
                              id=""
                              cols="30"
                              rows="5"
                              className="form-control"
                              placeholder="Write here.."
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                   

                    <div className="form-fields-row mb-5">
                      <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3">
                          <button
                            className="btn btn-grey-common"
                            type="submit"
                            onClick={onSubmitCreatePracticeArea}
                          >
                            Edit Question
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditQndA;
