import React,{createContext,useState} from 'react';

export const AuthContext =createContext();


const AuthContextProvider = (props) => {
    const [isLoggedIn,setIsLoggedIn]=useState(false);
    const [loggedInAdminInfo,setLoggedInAdminInfo]=useState([]);
  return (
      <AuthContext.Provider value={{isLoggedIn,setIsLoggedIn,loggedInAdminInfo,setLoggedInAdminInfo}}>
        {props.children}
      </AuthContext.Provider>
  )
}

export default AuthContextProvider