import { React, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import config from "../config/configg";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TailSpin } from "react-loader-spinner";

const BACKEND_URL = config.BACKEND_URL;

const UserRequest = () => {
  const [requestsList, setRequestsList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [userName, setUserName] = useState("");
  const [feedBack, setFeedback] = useState("");
  const [image, setImage] = useState("");
  const [testimonyId, setTestimonyId] = useState("");
  const [requestData, setRequestData] = useState({});
  const [option, setOption] = useState("all");
  const [openModal, setOpenModal] = useState(false);
  const [isContacted, setIsContacted] = useState(false);
  const [modalLoader,setModalLoder]=useState(false)
  const [deleteContact,setDeleteContact]=useState("")
  const [deleteModal,setDeleteModal]=useState(false)


  

  const [loading, setLoading] = useState(true);
  const history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchAllRequests();
    } else {
      history("/login");
    }
  }, [page, option]);

  const fetchAllRequests = async () => {
    let offset = page * 10;

    setLoading(true);

    const response = await fetch(
      `${BACKEND_URL}/admin/getAllUserRequestsForAdmin?skip=${offset}&limit=${10}&option=${option}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );
    const jsonnn = await response.json();
    console.log(jsonnn, "console the experts");
    setRequestsList(jsonnn.data.submittedRequestData);
    setCount(jsonnn.data.pages);

    setLoading(false);
  };

  const handlePageReset = async () => {
    setIsContacted(false);
    setRequestData({});
    setOpenModal(false);
  };
  const getRequestById = async (id) => {
    setModalLoder(true)
    const response = await fetch(
      `${BACKEND_URL}/admin/getUserRequestById?id=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );
    const jsonnn = await response.json();
    if (jsonnn.statusCode !== 200) {
      toast(jsonnn.message);
      setModalLoder(false)

    }
    setIsContacted(jsonnn.data.isContacted);
    setRequestData(jsonnn.data);
    setModalLoder(false)

  };

  const handlePageClick = (event) => {
    setPage(event.selected);
    fetchAllRequests();
  };

  const handleChange = (event) => {
    if (event.target.name === "userName") {
      setUserName(event.target.value);
    }
    if (event.target.name === "option") {
      setOption(event.target.value);
    }
    if (event.target.name === "isContacted") {
      setIsContacted(event.target.checked);
    }
  };

  const editUserRequest = async (id) => {
    // setLoading(true)
    let dataToSend = { id: id, isContacted: isContacted };

    const response = await fetch(
      `${BACKEND_URL}/admin/editUserRequestByAdmin`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(dataToSend),
      }
    );
    const jsonnn = await response.json();
    if (jsonnn.statusCode === 200) {
      toast(jsonnn.message);
      handlePageReset();
      fetchAllRequests();

    } else toast(jsonnn.message);


  };

  const deleteUserRequest = async (id) => {
    setDeleteModal(false)
    let dataToSend = { id: id };

    const response = await fetch(
      `${BACKEND_URL}/admin/deleteUserRequestByAdmin`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(dataToSend),
      }
    );
    const jsonnn = await response.json();
    if (jsonnn.statusCode === 200) {
      toast(jsonnn.message);
      handlePageReset();
      fetchAllRequests();
    } else toast(jsonnn.message);
  };

  return (
    <>
      <ToastContainer />
      {loading ? (
        <div className="custm-loader">
          <TailSpin color="#000" height={200} width={200} />
        </div>
      ) : null}

      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex mx-2">
                    <h3>Manage Contact US Requests</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-between">
            <div
              className="col-lg-6  "
              style={{ paddingTop: "25px", width: "25%" }}
            >
              <select
                className="form-select"
                name="option"
                onChange={handleChange}
                value={option}
              >
                <option value="all">All</option>
                <option value="contacted">Contacted</option>
                <option value="unContacted">Un Contacted</option>
              </select>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="admin-white-box">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="common-table-wrapper mt-0">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>User Name </th>
                              <th>Phone Number</th>
                              <th>Email Id</th>
                              <th>Firm Name</th>
                              <th>Is Contacted</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {requestsList &&
                              requestsList.length > 0 &&
                              requestsList.map((obj, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {obj.firstName} {obj.lastName}
                                    </td>
                                    <td>{obj.phoneNo}</td>
                                    <td>{obj.email}</td>
                                    <td>{obj.firmName}</td>
                                    <td>{obj.isContacted ? "Yes" : "No"}</td>

                                    <td>
                                      {obj.isContacted === false ? (
                                        <button
                                          className="btn"
                                          type="button"
                                          onClick={() => {
                                            getRequestById(obj._id);
                                            setOpenModal(true);
                                            // setLoading(true)
                                          }}
                                        >
                                          {" "}
                                          <img
                                            src="./assets/img/edit-pencil-icon.png"
                                            alt=""
                                          />{" "}
                                        </button>
                                      ) : (
                                        ""
                                      )}

                                      <button
                                        className="btn"
                                        type="button"
                                        onClick={() => {
                                          setDeleteContact(obj._id);
                                          setDeleteModal(true);
                                          // deleteUserRequest(obj._id);
                                        }}
                                      >
                                        {" "}
                                        <img
                                          src="./assets/img/delete-icon.png"
                                          alt=""
                                        />{" "}
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                        {count > 0 ? (
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={count}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-center"
                            }
                            initialPage={0}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     
      <Modal
        isOpen={openModal}
        toggle={() => setOpenModal(false)}
       
        className="  modal-dialog modal-dialog-centered "
      >

        <ModalHeader>
       
          <h5 class="modal-title" id="exampleModalLabel">
            Edit User Request
          </h5>
        </ModalHeader>
        <ModalBody>
       
          <div>
          {modalLoader ? (
          <div className="">
            <TailSpin color="#000" height={120} width={100} />
          </div>
        ) : <>
            <span>
              User Name: {requestData.firstName} {requestData.lastName}
            </span>
            <br />
            <span>Phone No: {requestData.phoneNo}</span>
            <br />
            <span>Email: {requestData.email}</span>
            <br />
            <span>Firm Name: {requestData.firmName}</span>
            <br />
            <span>
              Is Contacted:{" "}
              <input
                type="checkbox"
                onChange={handleChange}
                name="isContacted"
                checked={isContacted ? true : false}
                value={isContacted}
              ></input>
            </span>
            <br />
            </>}
          </div>
         
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-secondary"
            onClick={() => {
              handlePageReset();
            }}
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            onClick={() => {
              editUserRequest(requestData._id);
            }}
          >
            Edit Contact Us Request 
          </button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={deleteModal} toggle={() => deleteUserRequest(false)} className='  modal-dialog modal-dialog-centered '>
      
  
      <ModalHeader>
       <h5 class="modal-title" id="exampleModalLabel">Confirm </h5>
       
        </ModalHeader>
      <ModalBody>
        Please confirm you want to delete Contact US request.
      </ModalBody>
      <ModalFooter>
        <button type="button" class="btn btn-secondary" onClick={()=>{setDeleteModal(false);}}>Close</button>
        <button type="button" class="btn btn-primary" onClick={()=>deleteUserRequest(deleteContact)}>Delete Contact Us Request</button>
      </ModalFooter>
    
     
 </Modal>
    </>
  );
};

export default UserRequest;
