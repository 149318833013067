import Agent from "./super.js";
import config from "../config/configg";
import { ServerError } from "../utils/helpers";
const BACKEND_URL = config.BACKEND_URL;

function bookAnAppoitment(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/website/bookAnAppoitment`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function getQuesAndAns(cb) {
  Agent.fire("get", `${BACKEND_URL}/website/getQuesAndAns`).end((err, res) => {
    var error =
      err || res.error
        ? ServerError(res)
        : res.body && res.body.error
        ? ServerError(res)
        : null;
    if (typeof cb === "function") return cb(error, res && res.body);
  });
}
function deleteQuesAndAns(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/admin/deleteQuesAndAns`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function editQuesAndAns(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/admin/editQuesAndAns`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function addQuesAndAns(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/admin/addQuesAndAns`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function getQuesAndAnsById(payload, cb) {
  console.log(payload, "kjbqwb");
  Agent.fire("post", `${BACKEND_URL}/admin/getQuesAndAnsById`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}

export default {
  bookAnAppoitment,
  getQuesAndAns,
  deleteQuesAndAns,
  addQuesAndAns,
  editQuesAndAns,
  getQuesAndAnsById,
};
