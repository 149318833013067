import { React, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from '../config/configg';
const BACKEND_URL = config.BACKEND_URL;

const ManageFreelancer = () => {
  const history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("token")) {
    } else {
      history("/login");
    }

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex">
                    <h3>Manage Freelancer</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-short-nav-buttons">
                  <div className="table-btn-group">
                    <Link to="/addfreelancer">
                      <button className="btn black-fill" type="button">
                        Add Freelancer
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="admin-filter-navbar bg-grey">
            <div className="row align-items-center">
              <div className="col-lg-1">
                <h6 className="mb-0">Filters</h6>
              </div>
              <div className="col-lg">
                <div className="filter-select-box">
                  <select className="form-select">
                    <option selected="">Contact Type</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>

              <div className="col-lg">
                <div className="filter-select-box">
                  <select className="form-select">
                    <option selected="">Contact Group</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="filter-input-box">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Freelancer"
                  />
                  <img src="./assets/img/search-icon.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="admin-white-box">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="common-table-wrapper mt-0">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th> &nbsp; </th>
                              <th>Freelancer name </th>
                              <th>Email ID </th>
                              <th>Contact number </th>
                              <th>Joined Date</th>
                              <th> &nbsp; </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <img
                                  src="./assets/img/user-img.png"
                                  className="img img-fluid"
                                  alt=""
                                />
                              </td>
                              <td>
                                <h5>Vaibhav Jagtap</h5>
                              </td>
                              <td>
                                <h6>Vaibhavjagtap1212@gmail.com</h6>
                              </td>
                              <td>
                                <h6>90908-09089</h6>
                              </td>
                              <td>
                                <h6>
                                  Sept 14 2021
                                  <br /> By Aprzz James
                                </h6>
                              </td>
                              <td>
                                <div className="action-btn-group">
                                  <Link to="javascript:;">
                                    <button className="btn" type="button">
                                      {" "}
                                      <img
                                        src="./assets/img/eye-icon-black.png"
                                        alt=""
                                      />{" "}
                                    </button>
                                  </Link>
                                  <Link to="javascript:;">
                                    <button className="btn" type="button">
                                      {" "}
                                      <img
                                        src="./assets/img/edit-pencil-icon.png"
                                        alt=""
                                      />{" "}
                                    </button>
                                  </Link>
                                  <Link to="javascript:;">
                                    <button className="btn" type="button">
                                      {" "}
                                      <img
                                        src="./assets/img/delete-icon.png"
                                        alt=""
                                      />{" "}
                                    </button>
                                  </Link>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <img
                                  src="./assets/img/user-img.png"
                                  className="img img-fluid"
                                  alt=""
                                />
                              </td>
                              <td>
                                <h5>Vaibhav Jagtap</h5>
                              </td>
                              <td>
                                <h6>Vaibhavjagtap1212@gmail.com</h6>
                              </td>
                              <td>
                                <h6>90908-09089</h6>
                              </td>
                              <td>
                                <h6>
                                  Sept 14 2021
                                  <br /> By Aprzz James
                                </h6>
                              </td>
                              <td>
                                <div className="action-btn-group">
                                  <Link to="javascript:;">
                                    <button className="btn" type="button">
                                      {" "}
                                      <img
                                        src="./assets/img/eye-icon-black.png"
                                        alt=""
                                      />{" "}
                                    </button>
                                  </Link>
                                  <Link to="javascript:;">
                                    <button className="btn" type="button">
                                      {" "}
                                      <img
                                        src="./assets/img/edit-pencil-icon.png"
                                        alt=""
                                      />{" "}
                                    </button>
                                  </Link>
                                  <Link to="javascript:;">
                                    <button className="btn" type="button">
                                      {" "}
                                      <img
                                        src="./assets/img/delete-icon.png"
                                        alt=""
                                      />{" "}
                                    </button>
                                  </Link>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <img
                                  src="./assets/img/user-img.png"
                                  className="img img-fluid"
                                  alt=""
                                />
                              </td>
                              <td>
                                <h5>Vaibhav Jagtap</h5>
                              </td>
                              <td>
                                <h6>Vaibhavjagtap1212@gmail.com</h6>
                              </td>
                              <td>
                                <h6>90908-09089</h6>
                              </td>
                              <td>
                                <h6>
                                  Sept 14 2021
                                  <br /> By Aprzz James
                                </h6>
                              </td>
                              <td>
                                <div className="action-btn-group">
                                  <Link to="javascript:;">
                                    <button className="btn" type="button">
                                      {" "}
                                      <img
                                        src="./assets/img/eye-icon-black.png"
                                        alt=""
                                      />{" "}
                                    </button>
                                  </Link>
                                  <Link to="javascript:;">
                                    <button className="btn" type="button">
                                      {" "}
                                      <img
                                        src="./assets/img/edit-pencil-icon.png"
                                        alt=""
                                      />{" "}
                                    </button>
                                  </Link>
                                  <Link to="javascript:;">
                                    <button className="btn" type="button">
                                      {" "}
                                      <img
                                        src="./assets/img/delete-icon.png"
                                        alt=""
                                      />{" "}
                                    </button>
                                  </Link>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <img
                                  src="./assets/img/user-img.png"
                                  className="img img-fluid"
                                  alt=""
                                />
                              </td>
                              <td>
                                <h5>Vaibhav Jagtap</h5>
                              </td>
                              <td>
                                <h6>Vaibhavjagtap1212@gmail.com</h6>
                              </td>
                              <td>
                                <h6>90908-09089</h6>
                              </td>
                              <td>
                                <h6>
                                  Sept 14 2021
                                  <br /> By Aprzz James
                                </h6>
                              </td>
                              <td>
                                <div className="action-btn-group">
                                  <Link to="javascript:;">
                                    <button className="btn" type="button">
                                      {" "}
                                      <img
                                        src="./assets/img/eye-icon-black.png"
                                        alt=""
                                      />{" "}
                                    </button>
                                  </Link>
                                  <Link to="javascript:;">
                                    <button className="btn" type="button">
                                      {" "}
                                      <img
                                        src="./assets/img/edit-pencil-icon.png"
                                        alt=""
                                      />{" "}
                                    </button>
                                  </Link>
                                  <Link to="javascript:;">
                                    <button className="btn" type="button">
                                      {" "}
                                      <img
                                        src="./assets/img/delete-icon.png"
                                        alt=""
                                      />{" "}
                                    </button>
                                  </Link>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <img
                                  src="./assets/img/user-img.png"
                                  className="img img-fluid"
                                  alt=""
                                />
                              </td>
                              <td>
                                <h5>Vaibhav Jagtap</h5>
                              </td>
                              <td>
                                <h6>Vaibhavjagtap1212@gmail.com</h6>
                              </td>
                              <td>
                                <h6>90908-09089</h6>
                              </td>
                              <td>
                                <h6>
                                  Sept 14 2021
                                  <br /> By Aprzz James
                                </h6>
                              </td>
                              <td>
                                <div className="action-btn-group">
                                  <Link to="javascript:;">
                                    <button className="btn" type="button">
                                      {" "}
                                      <img
                                        src="./assets/img/eye-icon-black.png"
                                        alt=""
                                      />{" "}
                                    </button>
                                  </Link>
                                  <Link to="javascript:;">
                                    <button className="btn" type="button">
                                      {" "}
                                      <img
                                        src="./assets/img/edit-pencil-icon.png"
                                        alt=""
                                      />{" "}
                                    </button>
                                  </Link>
                                  <Link to="javascript:;">
                                    <button className="btn" type="button">
                                      {" "}
                                      <img
                                        src="./assets/img/delete-icon.png"
                                        alt=""
                                      />{" "}
                                    </button>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ManageFreelancer;
