import { React, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import config from "../config/configg";

import axios from "axios";
import { useNavigate,useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TailSpin } from "react-loader-spinner";

const BACKEND_URL = config.BACKEND_URL;

const Testimony = () => {
  let params = useParams()
  const [testimonyList, setTestimonyList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [userName, setUserName] = useState("");
  const [feedBack, setFeedback] = useState("");
  const [image, setImage] = useState("");
  const [testimonyId, setTestimonyId] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");

  
  const history = useNavigate();
  const handlePageReset = async () => {
    setUserName("");
    setFeedback("");
    setImage("");
    setTestimonyId("");
  };
  useEffect(()=>{
    setTestimonyId(params.id)
    if(params.id){
      getTestimonyById(params.id)
    }
  },[])
 
  const getTestimonyById = async (id) => {
    setLoading(true)
    const response = await fetch(
      `${BACKEND_URL}/admin/getTestimonyByIdForAdmin?id=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );
    const jsonnn = await response.json();

    setUserName(jsonnn.data.name);
    setFeedback(jsonnn.data.feedback);
    setImage(jsonnn.data.image);
    setTestimonyId(jsonnn.data._id);
    setLoading(false)
  
}
  const handleChange = (event) => {
    if (event.target.name === "userName") {
      setUserName(event.target.value);
    }
    if (event.target.name === "feedBack") {
      setFeedback(event.target.value);
    }
;
}

  const addOrEditTestimony = async () => {
    setLoading(true)
    if (!feedBack) {
      toast("Feedback is not allowed to be empty");
    }
    let dataToSend = { name: userName, feedback: feedBack, image: image };
    if (testimonyId) {
      dataToSend.id = testimonyId;
      const response = await fetch(
        `${BACKEND_URL}/admin/editTestimonyByAdmin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify(dataToSend),
        }
      );
      const jsonnn = await response.json();
      if (jsonnn.statusCode === 200) {
        setLoading(false);
        handlePageReset()
        toast("Testimony has been edit successfully.");
        setData(jsonnn)
        history("/testimonies")

      } else
      { 
            toast(jsonnn.message);
            setLoading(false)
      }

    } else {
      const response = await fetch(`${BACKEND_URL}/admin/addTestimonyByAdmin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(dataToSend),
      });
      const jsonnn = await response.json();
      if (jsonnn.statusCode === 200) {
        setLoading(false)
        handlePageReset()
        setData(jsonnn)
        toast("New Testimony has been added successfully.");
        // window.location.reload();
        history("/testimonies")
      } else toast(jsonnn.message);
      handlePageReset()
      setLoading(false)

    }
  };
  const uploadFilesUsingMulter = async (e, i) => {
    setLoading(true);
    var formData = new FormData();
    formData.append("files", e.target.files[0]);
    const axiosRes = await axios({
      method: "post",
      url: `${BACKEND_URL}/admin/uploadfile`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
    console.log(axiosRes.data.data[0].path);
    if (i === 1) {
      setImage(axiosRes.data.data[0].path);
    }
    setLoading(false);
  };
  return (
    <>
      <ToastContainer />
      {loading ? (
        <div className="custm-loader">
          <TailSpin color="#000" height={200} width={200} />
        </div>
      ) : null}
      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex mx-2">
                    {testimonyId ? <><h3>Edit Testimony</h3></>:
                    <h3>Add Testimony</h3>}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="basic-info-wrp">
                <div className="admin-white-box p-0 pt-4">
                  <div className="form-feilds-container">
                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>User Name</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <input
                              type="text"
                              name="userName"
                              onChange={handleChange}
                              value={userName}
                              className="form-control"
                              placeholder="User Name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Feedback</h3>
                        </div>
                        <div className="col-lg-5">
                          <div className="form-group">
                            <textarea
                              name="feedBack"
                              onChange={handleChange}
                              value={feedBack}
                              id=""
                              cols="30"
                              rows="5"
                              className="form-control"
                              placeholder="Write here.."
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Upload Image</h3>
                        </div>
                        <div className="col-lg-3">
                          {image ? (
                            <img
                              src={image}
                              alt="User image"
                              width="100px"
                              height="100px"
                            ></img>
                          ) : (
                            ""
                          )}
                          <div className="form-group">
                            <input
                              type="file"
                              name="upload"
                              accept="image/png, image/jpeg"
                              className="form-control"
                              onChange={(e) => {
                                uploadFilesUsingMulter(e, 1);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row mb-5">
                      <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3">
                          <button
                            className="btn btn-grey-common"
                            type="submit"
                            onClick={() => {
                              addOrEditTestimony();
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </section>
    </>
  );
};

export default Testimony;
