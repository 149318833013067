import { React, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import config from '../config/configg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TailSpin } from "react-loader-spinner";

import { Modal, ModalHeader,ModalFooter,ModalBody } from "reactstrap";
const BACKEND_URL = config.BACKEND_URL;

const AddPractice = () => {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [getCategories, setGetCategories] = useState([]);
  const [loading,setLoading]=useState(false)
  const [practiceArea, setPracticeArea] = useState(
    {
      name: '',
          description: '',
          
    }
  );
  const [image, setImage] = useState("");
  const history = useNavigate();
  const [inputKey,setInputKey]=useState('');
  const fetchAllCategories = async () => {
    const response = await fetch(
      `${BACKEND_URL}/admin/getCategoriesData`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const jsonnn = await response.json();
    console.log("satyamtomar", jsonnn);
    setGetCategories(jsonnn.data);
    console.log("sdflkjsdfkjlda", getCategories);
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchAllCategories();
    } else {
      history("/login");
    }

    // eslint-disable-next-line
  }, []);
  const onSubmitCreatePracticeArea = async () => {
    setLoading(true)
    if(practiceArea.name.length<2)
    {
       toast('Enter a valid practice Area Name')
       return;
    }
    else if(practiceArea.description<1)
    {
      toast('Description must not be empty');
      return;
    }
    const response = await fetch(
      `${BACKEND_URL}/admin/createPracticeArea`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),

          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          name: practiceArea.name,
          description: practiceArea.description,
          original: image,
          categoryId: selectedCategory,
          
        }),
      }
    );
    const json = await response.json();
    if(json.statusCode===200){
    setLoading(false)

      toast('Practice Area Added Successfully');
    history("/managepractice");

      setPracticeArea([{name: '',
        description: '',
        }]);
      setInputKey('change');
      setInputKey('');
   setSelectedCategory(0);
 setPracticeArea(
    {
      name: '',
          description: '',         
    }
  );
  setImage("");

    }
    else{
    setLoading(false)

      toast(json.message);

    }
    // console.log(json, "addpractice");
  };
  const uploadFilesUsingMulter = async (e, i) => {
    // console.log("iiiihiihiohiin", "aloha0", e.target.files[0]);
    var formData = new FormData();
    formData.append("files", e.target.files[0]);
    const axiosRes = await axios({
      method: "post",
      url: `${BACKEND_URL}/admin/uploadfile`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
    // console.log(i, "idkkkkk", axiosRes);
    if (i === 1) {
      setImage(axiosRes.data.data[0].path);
    }
  };
  const onChangePracticeArea = (e) => {
    setPracticeArea({
      ...practiceArea,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
     {loading ? (
          <div className="custm-loader">
            <TailSpin color="#000" height={200} width={200} />
          </div>
        ) : null}
    <ToastContainer/>

      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex">
                    <h3>Add new Practice Area</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-short-nav-buttons">
                  <div className="table-btn-group">
                    <Link to="/managepractice">
                      <button className="btn" type="button">
                        {" "}
                        <i className="fa fa-long-arrow-left me-2"></i> Back
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="basic-info-wrp">
                <div className="admin-white-box p-0 pt-4">
                  <div className="form-feilds-container">
                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Practice area name</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <input
                              type="text"
                              onChange={onChangePracticeArea}
                              name="name"
                              className="form-control"
                              placeholder="Practice area name"
                              value={practiceArea?.name}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Category</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <select
                              className="form-select"
                              onChange={(e) => {
                                setSelectedCategory(e.target.value);
                              }}
                              value={selectedCategory}
                            >
                              <option  value="0">
                                Select Category
                              </option>
                              {getCategories &&
                                getCategories.map((obj, index) => {
                                  return (
                                    <option value={`${obj._id}`}>
                                      {obj.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Description</h3>
                        </div>
                        <div className="col-lg-5">
                          <div className="form-group">
                            <textarea
                              name="description"
                              onChange={onChangePracticeArea}
                              id=""
                              cols="30"
                              rows="5"
                              className="form-control"
                              placeholder="Write here.."
                              value={practiceArea?.description}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Upload Thumbnail icon</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <input
                              type="file"
                              accept="image/png,image/jpeg,image/jpg"
                              key={inputKey}
                              name="upload"
                              onChange={(e) => {
                                uploadFilesUsingMulter(e, 1);
                              }}
                              className="form-control"
                              placeholder="Attach Document"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row mb-5">
                      <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3">
                          <button
                            className="btn btn-grey-common"
                            type="submit"
                            onClick={onSubmitCreatePracticeArea}
                          >
                            Add Practice area
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddPractice;
