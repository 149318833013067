import moment from "moment";
import { React, useEffect, useState } from "react";
import QandAnsActions from "../actions/QandAns.actions";
import config from '../config/configg';
import { Link, useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { Modal, ModalHeader,ModalFooter,ModalBody } from "reactstrap";
const BACKEND_URL = config.BACKEND_URL;

const QueAndAns = () => {
  const history = useNavigate();
  const [question, setQuestion] = useState([]);
  const [getCategories, setGetCategories] = useState([]);
  const [loading,setLoading]=useState(false)
  const [deleteModal,setDeleteModal]=useState(false)
  const [obj,setObject]=useState("");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchAllPracticeAreaPopulated();
      fetchQuestionAnswer();
    } else {
      history("/login");
    }
    

    // eslint-disable-next-line
  }, []);
  const fetchQuestionAnswer=()=>{
    setLoading(true)
    QandAnsActions.getQuesAndAns((err,res)=>{
      if(err){

      }else{
          // console.log(res)
          setQuestion(res.data);
          setLoading(false)
      }
  })
      
  }

  
  const fetchAllPracticeAreaPopulated = async () => {
    const response = await fetch(
      `${BACKEND_URL}/admin/getPracticeAreaDataPopulated`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const jsonnn = await response.json();
    console.log(jsonnn);
    // setGetPracticeArea(jsonnn.data);

    console.log("all my practice areas are", jsonnn.data);
  };
  const handleDeletePracticeArea = async (obj) => {
      let payload={
          _id:obj._id
      }
      console.log(obj)
   QandAnsActions.deleteQuesAndAns(payload,(err,res)=>{
       if(err){

       }else{
        setDeleteModal(false);
        fetchQuestionAnswer()
          //  console.log(res)
           
       }
   })
    fetchAllPracticeAreaPopulated();
  };

  return (
    <>
     {loading ? (
          <div className="custm-loader">
            <TailSpin color="#000" height={200} width={200} />
          </div>
        ) : null}
      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex">
                    <h3>Manage frequently Questions</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-short-nav-buttons">
                  <div className="table-btn-group">
                    <Link to="/AddQandAns">
                      <button className="btn black-fill" type="button">
                      Add FAQ
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="admin-filter-navbar bg-grey">
            <div className="row align-items-center justify-content-end">
              <div className="col-lg-3">
                <div className="filter-input-box">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Practice Area"
                  />
                  <img src="./assets/img/search-icon.png" alt="" />
                </div>
              </div>
            </div>
          </div> */}
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="admin-white-box">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="common-table-wrapper mt-0">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Questions area </th>
                              {/* <th>Categories </th> */}
                              <th>Answer</th>
                              <th> &nbsp; </th>
                            </tr>
                          </thead>
                          <tbody>
                            {question.length>0 &&
                              question.map((obj, index) => {
                                return (
                                  <>
                                    <tr>
                                      <td>
                                        <h5>{obj.question}</h5>
                                      </td>
                                    

                                      <td>
                                        <h6>
                                           {obj.answer}
                                        </h6>
                                      </td>
                                      <td>
                                        <div className="action-btn-group">
                                          <Link
                                            to={`/editQandAns/${obj._id}`}
                                          >
                                            <button
                                              className="btn"
                                              type="button"
                                            >
                                              {" "}
                                              <img
                                                src="./assets/img/edit-pencil-icon.png"
                                                alt=""
                                              />{" "}
                                            </button>
                                          </Link>
                                          <a>
                                            <button
                                              className="btn"
                                              type="button"
                                              onClick={() => {
                                                setObject(obj)
                                                // handleDeletePracticeArea(obj);
                                                setDeleteModal(true)
                                              }}
                                            >
                                              {" "}
                                              <img
                                                src="./assets/img/delete-icon.png"
                                                alt=""
                                              />{" "}
                                            </button>
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal isOpen={deleteModal} toggle={() => setDeleteModal(false)} className='  modal-dialog modal-dialog-centered '>
      
  
      <ModalHeader>
       <h5 class="modal-title" id="exampleModalLabel">Confirm </h5>
       
        </ModalHeader>
      <ModalBody>
        Please Confirm to Delete Question
      </ModalBody>
      <ModalFooter>
        <button type="button" class="btn btn-secondary" onClick={()=>{setDeleteModal(false);}}>Close</button>
        <button type="button" class="btn btn-primary" onClick={()=>handleDeletePracticeArea(obj)}>Delete Question</button>
      </ModalFooter>
    
     
 </Modal>
    </>
  );
};

export default QueAndAns;
