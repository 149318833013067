import { React, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import config from "../config/configg";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TailSpin } from "react-loader-spinner";
import { Link,useParams } from "react-router-dom";
import { Modal, ModalHeader,ModalFooter,ModalBody } from "reactstrap";

const BACKEND_URL = config.BACKEND_URL;

const Testimony = () => {
  const [testimonyList, setTestimonyList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [userName, setUserName] = useState("");
  const [feedBack, setFeedback] = useState("");
  const [image, setImage] = useState("");
  const [testimonyId, setTestimonyId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteTestmony,setDeleteTestimony]=useState("")

  

  const [loading, setLoading] = useState(true);
  const history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchAllTestimonies();
    } else {
      history("/login");
    }
  }, [page]);

  const fetchAllTestimonies = async () => {
    let offset = page * 10;

    setLoading(true);

    const response = await fetch(
      `${BACKEND_URL}/admin/getTestimonies?skip=${offset}&limit=${10}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );
    const jsonnn = await response.json();
    console.log(jsonnn, "console the experts");
    setTestimonyList(jsonnn.data.feedback);
    setCount(jsonnn.data.pages);

    setLoading(false);
  };

  const handlePageReset = async () => {
    setUserName("");
    setFeedback("");
    setImage("");
    setTestimonyId("");
  };
  const getTestimonyById = async (id) => {
    history(`/addTestimony/${id}`)
  };

  const handlePageClick = (event) => {
    setPage(event.selected);
    fetchAllTestimonies();
  };

  const handleChange = (event) => {
    if (event.target.name === "userName") {
      setUserName(event.target.value);
    }
    if (event.target.name === "feedBack") {
      setFeedback(event.target.value);
    }
    // setPage(event.selected);
    // fetchAllTestimonies();
  };

  const addOrEditTestimony = async () => {
    if (!feedBack) {
      toast("Feedback is not allowed to be empty");
    }
    let dataToSend = { name: userName, feedback: feedBack, image: image };
    if (testimonyId) {
      dataToSend.id = testimonyId;
      const response = await fetch(
        `${BACKEND_URL}/admin/editTestimonyByAdmin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify(dataToSend),
        }
      );
      const jsonnn = await response.json();
      if (jsonnn.statusCode === 200) {
        toast(jsonnn.message);
        handlePageReset();
        fetchAllTestimonies();
      } else toast(jsonnn.message);
    } else {
      const response = await fetch(`${BACKEND_URL}/admin/addTestimonyByAdmin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(dataToSend),
      });
      const jsonnn = await response.json();
      if (jsonnn.statusCode === 200) {
        toast(jsonnn.message);
        handlePageReset();
        fetchAllTestimonies();
      } else toast(jsonnn.message);
    }
  };

  const uploadFilesUsingMulter = async (e, i) => {
    var formData = new FormData();
    formData.append("files", e.target.files[0]);
    const axiosRes = await axios({
      method: "post",
      url: `${BACKEND_URL}/admin/uploadfile`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
    console.log(axiosRes.data.data[0].path);
    if (i === 1) {
      setImage(axiosRes.data.data[0].path);
    }
  };

  const deleteTestimony = async (id) => {
    let dataToSend = { id: id };
    setDeleteModal(false)

    const response = await fetch(
      `${BACKEND_URL}/admin/deleteTestimonyByAdmin`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(dataToSend),
      }
    );
    const jsonnn = await response.json();
    if (jsonnn.statusCode === 200) {
      toast(jsonnn.message);
      handlePageReset();
      fetchAllTestimonies();
    } else toast(jsonnn.message);
  };

  return (
    <>
      <ToastContainer />
      {loading ? (
        <div className="custm-loader">
          <TailSpin color="#000" height={200} width={200} />
        </div>
      ) : null}

      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex mx-2">
                    <h3>List Testimony</h3>
                  </div>

                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-short-nav-buttons">
                  <div className="table-btn-group">
                    <Link to="/addTestimony">
                      <button className="btn black-fill" type="button">
                        Add New Testimony
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="admin-white-box">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="common-table-wrapper mt-0">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>User Name </th>
                              <th>Testimony </th>
                              <th>Image</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {console.log("testminy",testimonyList)}
                            {testimonyList &&
                              testimonyList.length > 0 &&
                              testimonyList.map((obj, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{obj.name}</td>
                                    <td>{obj.feedback}</td>
                                    <td>   <img src={`${obj.image}`} height="30px" width="30px" alt="" /></td>
                                    <td>
                                      <button
                                        className="btn"
                                        type="button"
                                        onClick={() => {
                                          getTestimonyById(obj._id);
                                        }}
                                      >
                                        {" "}
                                        <img
                                          src="./assets/img/edit-pencil-icon.png"
                                          alt=""
                                        />{" "}
                                      </button>
                                      <button
                                        className="btn"
                                        type="button"
                                        onClick={() => {

                                          setDeleteTestimony(obj._id);
                                          setDeleteModal(true);
                                        }}
                                      >
                                        {" "}
                                        <img
                                          src="./assets/img/delete-icon.png"
                                          alt=""
                                        />{" "}
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                        {count > 0 ? (
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={count}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-center"
                            }
                            initialPage={0}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal isOpen={deleteModal} toggle={() => deleteTestimony(false)} className='  modal-dialog modal-dialog-centered '>
      
  
      <ModalHeader>
       <h5 class="modal-title" id="exampleModalLabel">Confirm </h5>
       
        </ModalHeader>
      <ModalBody>
        Please Confirm to Delete Testimony
      </ModalBody>
      <ModalFooter>
        <button type="button" class="btn btn-secondary" onClick={()=>{setDeleteModal(false);}}>Close</button>
        <button type="button" class="btn btn-primary" onClick={()=>deleteTestimony(deleteTestmony)}>Delete Testimony</button>
      </ModalFooter>
    
     
 </Modal>
    </>
  );
};

export default Testimony;
