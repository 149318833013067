import { React, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import config from '../config/configg';
import { Modal, ModalHeader,ModalFooter,ModalBody } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TailSpin } from "react-loader-spinner";

const BACKEND_URL = config.BACKEND_URL;

const EditExpert = (props) => {
  const history = useNavigate();
  const [mainLoader,setMainLoader]=useState(false);
  const { expertId } = useParams();
  const [loading,setLoading]=useState(false)
  const [expertcredential, setexpertcredential] = useState({
    bio: "",
    audioFilePath: "",
    videoFilePath: "",

    firstName: "",
    email: "",
    mobileNo: "",
    lastName: "",
    profilePic: "",
    otp: "",
    experience: 0,
  });
  
  const [accountType, setAccountType] = useState("");

  const [getProfilePic, setGetProfilePic] = useState("");
  const [getAudioFilePath, setGetAudioFilePath] = useState("");
  const [getVideoFilePath, setGetVideoFilePath] = useState("");
  const [getDocument, setGetDocument] = useState([
    { fileName: "", fileType: "", link: "", mimeType: "" },
  ]);

  const [getPracticeArea, setGetPracticeArea] = useState([]);
  const [getCategories, setGetCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedPracticeArea, setSelectedPracticeArea] = useState(0);
  const [dummy, setDummy] = useState(0);
  const fetchAllCategories = async () => {
    const response = await fetch(
      `${BACKEND_URL}/admin/getCategoriesData`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const jsonnn = await response.json();
    console.log("satyamtomar", jsonnn);
    setGetCategories(jsonnn.data);
    // console.log("sdflkjsdfkjlda", getCategories);
  };
  // const fetchAllPracticeArea = async () => {
  //   const response = await fetch(
  //     `${BACKEND_URL}/admin/getPracticeAreaData`,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         // 'Content-Type': 'application/x-www-form-urlencoded',
  //       },
  //     }
  //   );
  //   const jsonnn = await response.json();
  //   // console.log(jsonnn);
  //   setGetPracticeArea(jsonnn.data);
  // };
  const fetchExpertDetails = async () => {
    setLoading(true)
    const response = await fetch(
      `${BACKEND_URL}/admin/getExpertDetails/${expertId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),

          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const jsonnn = await response.json();
    // console.log(jsonn
    setLoading(false)

    setSelectedCategory(jsonnn.data.category);

    setDummy(1);
    setSelectedPracticeArea(jsonnn.data.practiceArea[0]);

    setDummy(1);
    setGetDocument([
      {
        fileName: jsonnn.data.document[0].fileName,
        fileType: jsonnn.data.document[0].fileType,
        link: jsonnn.data.document[0].link,
        mimeType: jsonnn.data.document[0].mimeType,
      },
    ]);
    setDummy(1);
    setGetProfilePic(jsonnn.data.userId.profilePic);
    console.log("this is expert Data",jsonnn.data.accountType);
    setAccountType(jsonnn.data.accountType)
    setexpertcredential({
      bio: jsonnn.data.bio,
      audioFilePath: jsonnn.data.audioFilePath,
      videoFilePath: jsonnn.data.videoFilePath,

      firstName: jsonnn.data.userId.firstName,
      email: jsonnn.data.userId.email,
      mobileNo: jsonnn.data.userId.mobileNo,
      lastName: jsonnn.data.userId.lastName,
      otp: jsonnn.data.userId.otp,
      experience: jsonnn.data.experience,
    });
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      // fetchAllPracticeArea();
      fetchAllCategories();
      fetchExpertDetails();
    } else {
      history("/login");
    }

    // eslint-disable-next-line
  }, []);
  const onSubmitEditExpert = async () => {
    setLoading(true);
    if(expertcredential.firstName.length<1)
    {
      toast('Enter valid firstName');
      return;
    }
    else if(expertcredential.lastName.length<1)
    {
      toast('Enter valid lastName');
      return;
    }
    else if(expertcredential.mobileNo.length<10)
    {
      toast('Enter Valid mobileNo');
      return;
    }
    else if(expertcredential.email.length<5)
    {
      // console.log(email.length,'length of email')
      toast('enter a valid email');
      return;
    }
    console.log("this is expert type" ,accountType)
    const response = await fetch(
      `${BACKEND_URL}/admin/editExpertByAdmin/${expertId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),

          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          firstName: expertcredential.firstName,
          lastName: expertcredential.lastName,
          email: expertcredential.email,
          mobileNo: expertcredential.mobileNo,
          profilePic: getProfilePic,
          document: getDocument,
          category: selectedCategory,
          practiceArea: selectedPracticeArea,
          bio: expertcredential.bio,
          audioFilePath: getAudioFilePath,
          videoFilePath: getVideoFilePath,
          accountType: accountType,
          otp: "123456",
          experience: expertcredential.experience,

        }),
      }
    );
    const json = await response.json();
     if(json.statusCode===200)
     {

       toast('Expert has been edited successfully');
       setLoading(false);
        history("/expertlisting")

     }
     else {
       toast(json.message);
       setLoading(false);

     }
    console.log(json);
  };

  const onChangeExpert = (e) => {
    setexpertcredential({
      ...expertcredential,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(()=>{
    fetchAllPracticeArea();
  },[selectedCategory])
    const fetchAllPracticeArea = async () => {
      // selectedCategory
      console.log("this is cate" ,selectedCategory )
      const response = await fetch(
        `${BACKEND_URL}/admin/getPracticeAreaData?selectedCategory=${selectedCategory}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      const jsonnn = await response.json();
      console.log(jsonnn);
      setGetPracticeArea(jsonnn.data);
    };
  const uploadFilesUsingMulter = async (e, i) => {
    // console.log("iiiihiihiohiin", "aloha0", e.target.files[0]);
    var formData = new FormData();
    formData.append("files", e.target.files[0]);
    const axiosRes = await axios({
      method: "post",
      url: `${BACKEND_URL}/admin/uploadfile`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
    console.log(i, "idkkkkk", axiosRes);
    if (i === 1) {
      setGetProfilePic(axiosRes.data.data[0].path);
    } else if (i === 2) {
      setGetAudioFilePath(axiosRes.data.data[0].path);
    } else if (i === 3) {
      setGetVideoFilePath(axiosRes.data.data[0].path);
    } else if (i === 4) {
      setGetDocument([
        {
          fileName: axiosRes.data.data[0].filename,
          fileType: axiosRes.data.data[0].filename,
          link: axiosRes.data.data[0].path,
          mimeType: axiosRes.data.data[0].mimetype,
        },
      ]);
    }
  };
  const handleNewDocumnentUpload = () => {
    setGetDocument([
      {
        fileName: "",
        fileType: "",
        link: "",
        mimeType: "",
      },
    ]);
  };

  return (
    <>
    {loading ? (
          <div className="custm-loader">
            <TailSpin color="#000" height={200} width={200} />
          </div>
        ) : null}
    <ToastContainer/>
    {/* {mainLoader ? (
          <div className="custm-loader">
            <TailSpin color="#000" height={200} width={200} />
          </div>
        ) : null} */}
     
      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex">
                    <h3> Edit Expert</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-short-nav-buttons">
                  <div className="table-btn-group">
                    <Link to="/expertlisting">
                      <button className="btn" type="button">
                        {" "}
                        <i className="fa fa-long-arrow-left me-2"></i> Back
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="basic-info-wrp">
                <div className="admin-white-box p-0">
                  <div className="basic-info-header">
                    <h5>Edit expert</h5>
                  </div>
                  
                  <div className="form-feilds-container">
                    
                    <div className="form-fields-row">
                    <div className="row">
                  <div className="col-lg-3">
                          <h3>Expert Type</h3>
                    </div>
                  <div className="col-lg-3">
                          <div className="form-group">
                            <select
                              className="form-select"
                              onChange={(e) => {
                                setAccountType(e.target.value);
                              }}
                              value={accountType}
                            >
                              <option value="expert">Expert</option>
                              <option value="freelancer">Freelancer</option>
                            </select>
                          </div>
                        </div>
                        </div><br />

                      <div className="row">
                        
                        <div className="col-lg-3">
                          <h3>Expert name</h3>
                        </div>

                        <div className="col-lg-3">
                        
                          <div className="form-group">

                            {console.log(
                              expertcredential,
                              "expertdetailssssss"
                            )}{" "}
                            <input
                              type="text"
                              name="firstName"
                              onChange={onChangeExpert}
                              value={expertcredential?.firstName}
                              className="form-control"
                              placeholder="First name"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <input
                              type="text"
                              name="lastName"
                              onChange={onChangeExpert}
                              value={expertcredential?.lastName}
                              className="form-control"
                              placeholder="Last name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Category</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <select
                              className="form-select"
                              onChange={(e) => {
                                setSelectedCategory(e.target.value);
                              }}
                              value={selectedCategory}
                            >
                              {getCategories &&
                                getCategories.map((obj, index) => {
                                  return (
                                    <option value={`${obj._id}`}>
                                      {obj.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <Link to="/addcategories">Add new Category</Link>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Practice area</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <select
                              className="form-select"
                              onChange={(e) => {
                                setSelectedPracticeArea(e.target.value);
                              }}
                              value={selectedPracticeArea}
                            >
                              {getPracticeArea &&
                                getPracticeArea.map((obj, index) => {
                                  return (
                                    <option value={`${obj._id}`}>
                                      {obj.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <Link to="/addpractice">Add new Practice area</Link>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Experience</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <input
                              type="text"
                              name="experience"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              maxLength='2'
                              value={expertcredential?.experience}
                              onChange={onChangeExpert}
                              className="form-control"
                              placeholder="Expert experience"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Contact number</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <input
                              type="text"
                              name="mobileNo"
                              onChange={onChangeExpert}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              
                              value={expertcredential.mobileNo}
                             maxLength="14"
                              
                              className="form-control"
                              placeholder="Enter mobile number"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Email Id</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              onChange={onChangeExpert}
                              value={expertcredential?.email}
                              className="form-control"
                              placeholder="Enter Email ID"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Upload Document </h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            {
                              console.log("this is getDocument" , getDocument)
                            }
                            {getDocument[0]?.fileName ? (
                              <span>
                                <img
                                    src={`${getDocument[0]?.link}`}
                                    alt=""
                                  />
                                <button
                                  className="btn"
                                  type="button"
                                  onClick={handleNewDocumnentUpload}
                                >
                                  {" "}
                                  <img
                                    src="/assets/img/delete-icon1.png"
                                    alt=""
                                  />{" "}
                                </button>
                              </span>
                            ) : (
                              <input
                                type="file"
                                accept="image/png,image/jpeg,image/jpg"
                                onChange={(e) => {
                                  uploadFilesUsingMulter(e, 4);
                                }}
                                className="form-control"
                                placeholder="Attach Document"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row mb-5">
                      <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3">
                          <button
                            className="btn btn-grey-common"
                            type="submit"
                            onClick={onSubmitEditExpert}
                          >
                            Edit Expert
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditExpert;
