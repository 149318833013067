
import firebase from 'firebase/compat/app';
import "firebase/compat/auth"
import 'firebase/compat/firestore'

//seraphic
// const firebaseConfig = {
//   apiKey: "AIzaSyCV7xXGdJqo4nnIwIXQ6gIKVtYEJB9U2Yg",
//   authDomain: "borhan-seraphic.firebaseapp.com",
//   projectId: "borhan-seraphic",
//   storageBucket: "borhan-seraphic.appspot.com",
//   messagingSenderId: "680223767289",
//   appId: "1:680223767289:web:b2c71aeef6f964883c4028",
//   measurementId: "G-Q995VQHTZ8"
// };
// vicky
const firebaseConfig = {
apiKey: " AIzaSyBEDL37MFBYHUOJMnKo-n-6VfZb21jTFOk",
  authDomain: "borhan-seraphic-vicky.firebaseapp.com",
  projectId: "borhan-seraphic-vicky",
  storageBucket: "borhan-seraphic-vicky.appspot.com",
  messagingSenderId: "84132536105",
  appId: "1:84132536105:web:2b0ca65d0263f10453e138",
  measurementId: "G-M5W6KJMLMM"
};

// firebase.initializeApp(firebaseConfig);
// let auth = firebase.auth();
// export { auth, firebase };

// Initialize Firebase

firebase.initializeApp(firebaseConfig);

// Export Firebase modules you'll use (optional)
const auth = firebase.auth();
const firestore = firebase.firestore();

export { auth, firestore };