import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "../config/configg";
import appConstants from "../appConstants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const BACKEND_URL = config.BACKEND_URL;

const EditLegalSubscription = () => {
  const [planName, setPlanName] = useState("");
  const [subscriptionData, setSubscriptionData] = useState({});
  const [planAmount, setPlanAmount] = useState(0);
  const [employeesNum, setEmployeesNum] = useState(0);
//   const [walletAmount, setWalletAmount] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);
  const [planDuration, setPlanDuration] = useState(1);
//   const [subscriptionFor, setSubscriptionFor] = useState("")
  const { subscriptionId } = useParams();

  const history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      getSubscriptionType();
    } else {
      history("/login");
    }

    // eslint-disable-next-line
  }, []);

  const handleChange = async (event) => {
    if (event.target.name === "planName") {
      setPlanName(event.target.value);
    }
    if (event.target.name === "planAmount") {
      setPlanAmount(event.target.value);
    }
    // if (event.target.name === "walletAmount") {
    //   setWalletAmount(event.target.value);
    // }
    if (event.target.name === "discountValue") {
      setDiscountValue(event.target.value);
    }
    if (event.target.name === "planDuration") {
      setPlanDuration(event.target.value);
    }
    if (event.target.name === "employeesNum") {
      setEmployeesNum(event.target.value);
    }
  };
  const handlePageReset = async () => {
    setPlanName("");

    setPlanAmount(0);
    // setSubscriptionFor("")

    setEmployeesNum(0)
    // setWalletAmount(0);

    setDiscountValue(0);

    setPlanDuration(1);
  };
  const getSubscriptionType = async () => {
    let dataToSend = {
      id: subscriptionId,
    };
    const response = await fetch(
      `${BACKEND_URL}/admin/getLegalSubscriptionTypeByIdForAdmin`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),

          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(dataToSend),
      }
    );
    const jsonnn = await response.json();

    if (jsonnn.statusCode !== 200) {
      toast.error(jsonnn.message);
    } else {
      setSubscriptionData(jsonnn.data);
      setPlanAmount(jsonnn.data.planAmount);
      setPlanName(jsonnn.data.planName);
      setEmployeesNum(jsonnn.data.employeesNum);
    //   setWalletAmount(jsonnn.data.walletAmount);
      setDiscountValue(jsonnn.data.discountValue);
      setPlanDuration(jsonnn.data.planDuration);
    //   setSubscriptionFor(jsonnn.data.subscriptionFor)
    }
  };
  const editSubscriptionType = async () => {
    if (planAmount === 0 || planName === "") {
      if (planAmount === 0) {
        toast.error("Plan amount must be greater than zero");
      }
      if (planName === "") {
        toast.error("Plan Name is required");
      }
    } 
    // else if (walletAmount === 0 && subscriptionFor === "borhanUser") {
    //   toast.error("Wallet amount must be greater than zero");
    // }
     else if (planAmount - discountValue > 5000) {
      toast.error(
        "Difference between plan amount and discount value must be less than or equal to 5000"
      );
    } else if (
      parseInt(planAmount) > 0 &&
      parseInt(discountValue) > 0 &&
      parseInt(planAmount) <= parseInt(discountValue)
    ) {
      console.log("planAmount", typeof discountValue);
      console.log("planAmount", planAmount);
      // toast.error("Discount should be less than plan amount");
    } else {
      let dataToSend = {
        id: subscriptionId,
        planName,
        planAmount,
        employeesNum,
        // walletAmount: subscriptionFor === "borhanUser" ? walletAmount : 0,
        discountValue,
        planDuration,
        // subscriptionFor
      };
      const response = await fetch(
        `${BACKEND_URL}/admin/editLegalSubscriptionTypeByAdmin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),

            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify(dataToSend),
        }
      );
      const jsonnn = await response.json();
      console.log("jsonnn", jsonnn.message);
      if (jsonnn.statusCode !== 200) {
        toast.error(jsonnn.message);
      } else {
        handlePageReset();
        toast(jsonnn.message);
        history("/ManagelegalSubscription");
      }
    }
  };
  return (
    <>
      <ToastContainer />
      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-9">
                <div className="">
                  <div className="admin-title-flex">
                    <h3>Edit Subscription</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="basic-info-wrp">
                <div className="admin-white-box p-0">
                  <div className="basic-info-header">
                    <h5>Edit Subscription</h5>
                  </div>

                  <div className="form-feilds-container">

                    {/* <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Plan Duration</h3>
                        </div>

                        <div className="col-lg-4">
                          <div className="col-lg-4">
                            <div className="form-group">
                              <input
                                type="text"

                                name="subscriptionFor"
                                value={subscriptionFor}

                                className="form-control"

                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Subscription Plan Name</h3>
                        </div>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            min="0"
                            name="planName"
                            value={planName}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Plan Amount(Amount To Be Paid By User in OMR)</h3>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <input
                              type="number"
                              min="0"
                              name="planAmount"
                              value={planAmount}
                              onChange={handleChange}
                              className="form-control"
                              placeholder="0"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* {subscriptionFor === "borhanUser" ? (<div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>
                            Wallet Amount(Amount To Show In Wallet In OMR)
                          </h3>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="0"
                              min="0"
                              name="walletAmount"
                              value={walletAmount}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>) : ("")} */}

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Employees</h3>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              placeholder="0"
                              name="employeesNum"
                              value={employeesNum}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Discounted Price</h3>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              placeholder="0"
                              name="discountValue"
                              value={discountValue}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Plan Duration</h3>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <select
                              className="form-select"
                              name="planDuration"
                              onChange={handleChange}
                              value={planDuration}
                            >
                              <option
                                value={
                                  appConstants.subscriptionTimings.oneMonth
                                    .value
                                }
                              >
                                {
                                  appConstants.subscriptionTimings.oneMonth
                                    .display
                                }
                              </option>
                              <option
                                value={
                                  appConstants.subscriptionTimings.twoMonths
                                    .value
                                }
                              >
                                {
                                  appConstants.subscriptionTimings.twoMonths
                                    .display
                                }
                              </option>
                              <option
                                value={
                                  appConstants.subscriptionTimings.threeMonths
                                    .value
                                }
                              >
                                {
                                  appConstants.subscriptionTimings.threeMonths
                                    .display
                                }
                              </option>
                              <option
                                value={
                                  appConstants.subscriptionTimings.fourMonths
                                    .value
                                }
                              >
                                {
                                  appConstants.subscriptionTimings.fourMonths
                                    .display
                                }
                              </option>
                              <option
                                value={
                                  appConstants.subscriptionTimings.fiveMonths
                                    .value
                                }
                              >
                                {
                                  appConstants.subscriptionTimings.fiveMonths
                                    .display
                                }
                              </option>
                              <option
                                value={
                                  appConstants.subscriptionTimings.sixMonths
                                    .value
                                }
                              >
                                {
                                  appConstants.subscriptionTimings.sixMonths
                                    .display
                                }
                              </option>
                              <option
                                value={
                                  appConstants.subscriptionTimings.sevenMonths
                                    .value
                                }
                              >
                                {
                                  appConstants.subscriptionTimings.sevenMonths
                                    .display
                                }
                              </option>
                              <option
                                value={
                                  appConstants.subscriptionTimings.eightMonths
                                    .value
                                }
                              >
                                {
                                  appConstants.subscriptionTimings.eightMonths
                                    .display
                                }
                              </option>
                              <option
                                value={
                                  appConstants.subscriptionTimings.nineMonths
                                    .value
                                }
                              >
                                {
                                  appConstants.subscriptionTimings.nineMonths
                                    .display
                                }
                              </option>
                              <option
                                value={
                                  appConstants.subscriptionTimings.tenMonths
                                    .value
                                }
                              >
                                {
                                  appConstants.subscriptionTimings.tenMonths
                                    .display
                                }
                              </option>
                              <option
                                value={
                                  appConstants.subscriptionTimings.elevenMonths
                                    .value
                                }
                              >
                                {
                                  appConstants.subscriptionTimings.elevenMonths
                                    .display
                                }
                              </option>
                              <option
                                value={
                                  appConstants.subscriptionTimings.twelveMonths
                                    .value
                                }
                              >
                                {
                                  appConstants.subscriptionTimings.twelveMonths
                                    .display
                                }
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="form-fields-row mb-5">
                    <div className="row">
                      <div className="col-lg-3"></div>
                      <div className="col-lg-3">
                        <button
                          className="btn btn-grey-common"
                          type="submit"
                          onClick={() => editSubscriptionType()}
                        >
                          Edit Subscription plan
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditLegalSubscription;
