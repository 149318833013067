import { React, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import QandAnsActions from "../actions/QandAns.actions";
import { TailSpin } from "react-loader-spinner";
const AddQndA = () => {

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [loading,setLoading]=useState(false)
  const history = useNavigate();
 
  useEffect(() => {
    if (localStorage.getItem("token")) {
    //   fetchAllCategories();
    } else {
      history("/login");
    }

    // eslint-disable-next-line
  }, []);
  const onSubmitCreatePracticeArea =  () => {
    setLoading(true)

      let payload={
          question:question,
          answer:answer
      }
      if(!answer&&!question){
        alert("fill both")  
        return
      }
    QandAnsActions.addQuesAndAns(payload,(err,res)=>{
        if(err){
          setLoading(false)

        }else{
            console.log(res)
          setLoading(false)
            // alert("add Q and ANswer")
            history("/QandAns");
        }
    })
};
 


  return (
    <>
    {loading ? (
          <div className="custm-loader">
            <TailSpin color="#000" height={200} width={200} />
          </div>
        ) : null}
      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex">
                    <h3>Add new frequently Questions</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-short-nav-buttons">
                  <div className="table-btn-group">
                    <Link to="/QandAns">
                      <button className="btn" type="button">
                        {" "}
                        <i className="fa fa-long-arrow-left me-2"></i> Back
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="basic-info-wrp">
                <div className="admin-white-box p-0 pt-4">
                  <div className="form-feilds-container">
                  <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Question</h3>
                        </div>
                        <div className="col-lg-5">
                          <div className="form-group">
                            <textarea
                              name="description"
                              onChange={e=>{
                                setQuestion(e.target.value)
                              }}
                              id=""
                              cols="30"
                              rows="5"
                              className="form-control"
                              placeholder="Write here.."
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                 

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Answer</h3>
                        </div>
                        <div className="col-lg-5">
                          <div className="form-group">
                            <textarea
                              name="description"
                              onChange={e=>{
                                  setAnswer(e.target.value)
                              }}
                              id=""
                              cols="30"
                              rows="5"
                              className="form-control"
                              placeholder="Write here.."
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                   

                    <div className="form-fields-row mb-5">
                      <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3">
                          <button
                            className="btn btn-grey-common"
                            type="submit"
                            onClick={onSubmitCreatePracticeArea}
                          >
                            Add Question
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddQndA;
