import { React, useEffect, useState ,useContext} from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { AuthContext } from "../context/AuthContext";
import config from '../config/configg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, ModalHeader,ModalFooter,ModalBody } from "reactstrap";
import { TailSpin } from "react-loader-spinner";
const BACKEND_URL = config.BACKEND_URL;

const ManageCategories = () => {
  const {isLoggedIn,setIsLoggedIn,loggedInAdminInfo,setLoggedInAdminInfo}=useContext(AuthContext);

  const history = useNavigate();
  const [getCategories, setGetCategories] = useState([]);
    const [loading,setLoading]=useState(true);
  const [search, setSearch] = useState("");
  const [deleteModal,setDeleteModal]=useState(false);
  const [categoryToBeDeleted,setCategoryToBeDeleted]=useState([]);
  useEffect(() => {
    if (isLoggedIn) {
      fetchAllCategories();
    } else {
      history("/login");
    }

    // eslint-disable-next-line
  }, [search]);
  const fetchAllCategories = async () => {

    const response = await fetch(
      `${BACKEND_URL}/admin/getCategoriesData?search=${search}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const jsonnn = await response.json();
    setGetCategories(jsonnn.data);
    setLoading(false)
  };
  const handleDeleteCategory = async (obj) => {
    const response = await fetch(
      `${BACKEND_URL}/admin/deleteCategories/${obj._id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const jsonnn = await response.json();
    console.log(jsonnn, "deleted category is");
    if(jsonnn.statusCode===200)
    {
      toast('Category successfully deleted');
      setDeleteModal(false);
    fetchAllCategories();
    }
  };
  const searchData = (e) => {
    setSearch(e.target.value);
  };
  return (
    <>
     {loading ? (
          <div className="custm-loader">
            <TailSpin color="#000" height={200} width={200} />
          </div>
        ) : null}
      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex">
                    <h3>Manage Categories</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-short-nav-buttons">
                  <div className="table-btn-group">
                    <Link to="/addcategories">
                      <button className="btn black-fill" type="button">
                        Add Categories
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="admin-filter-navbar bg-grey">
            <div className="row align-items-center">
              {/* <div className="col-lg-1">
                <h6 className="mb-0">Filters</h6>
              </div>
              <div className="col-lg">
                <div className="filter-select-box">
                  <select className="form-select">
                    <option selected="">Contact Type</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div> */}

              <div className="col-lg-8"></div>

              <div className="col-lg">
                <div className="filter-input-box">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Category"
                    onChange={searchData}
                  />
                  <img src="./assets/img/search-icon.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="admin-white-box">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="common-table-wrapper mt-0">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Categories name</th>
                              {/* <th>Practice area</th> */}
                              <th>Created Date And By </th>

                              <th> &nbsp; </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* <tr>
                                      
                                       <td>
                                            <ul className="legal-badge-list">
                                                <li><span>Legal 1</span></li>
                                            </ul>
                                       </td>
                                       
                                       <td>
                                        <h6>Legal</h6>
                                     </td>
                                       <td>
                                          <h6>Sept 14 2021
                                             <br/> By Aprzz James
                                          </h6>
                                       </td>
                                       <td>
                                          <div className="action-btn-group">
                                             <Link to="javascript:;"><button className="btn" type="button"> <img
                                                      src="./assets/img/eye-icon-black.png" alt="" /> </button></Link>
                                             <Link to="javascript:;"><button className="btn" type="button"> <img
                                                      src="./assets/img/edit-pencil-icon.png" alt="" /> </button></Link>
                                             <Link to="javascript:;"><button className="btn" type="button"> <img
                                                      src="./assets/img/delete-icon.png" alt="" /> </button></Link>
                                          </div>
                                       </td>
                                    </tr> */}
                            {getCategories &&
                              getCategories.map((obj, index) => {
                                return (
                                  <>
                                    <tr>
                                      <td>
                                        <ul className="legal-badge-list">
                                          <li>
                                            <span>{obj.name}</span>
                                          </li>
                                        </ul>
                                      </td>
                                      {/*                                       
                                      <td>
                                       <h6>Legal</h6>
                                    </td> */}
                                      <td>
                                        <h6>
                                          {moment(obj.createdAt).format(
                                            "MMMM Do YYYY, h:mm:ss a"
                                          )}
                                          <br />
                                          by {obj.createdBy}
                                        </h6>
                                      </td>
                                      <td>
                                        <div className="action-btn-group">
                                          {/* <Link to="javascript:;"><button className="btn" type="button"> <img
                                                     src="./assets/img/eye-icon-black.png" alt="" /> </button></Link> */}
                                          <Link
                                            to={`/managecategories/editcategory/${obj._id}`}
                                          >
                                            <button
                                              className="btn"
                                              type="button"
                                            >
                                              {" "}
                                              <img
                                                src="./assets/img/edit-pencil-icon.png"
                                                alt=""
                                              />{" "}
                                            </button>
                                          </Link>
                                          <a>
                                            <button
                                              className="btn"
                                              type="button"
                                              onClick={() => {
                                                // handleDeleteCategory(obj);
                                                setDeleteModal(true);
                                                setCategoryToBeDeleted(obj);
                                              }}
                                            >
                                              {" "}
                                              <img
                                                src="./assets/img/delete-icon.png"
                                                alt=""
                                              />{" "}
                                            </button>
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer/>
      <Modal isOpen={deleteModal} toggle={() => setDeleteModal(false)} className='  modal-dialog modal-dialog-centered '>
      
  
      <ModalHeader>
       <h5 class="modal-title" id="exampleModalLabel">Borhan </h5>
       
        </ModalHeader>
      <ModalBody>
      Are You Sure You Want To Delete ?
      </ModalBody>
      <ModalFooter>
        <button type="button" class="btn btn-secondary" onClick={()=>{setDeleteModal(false);}}>Close</button>
        <button type="button" class="btn btn-primary" onClick={()=>handleDeleteCategory(categoryToBeDeleted)}>Delete Category</button>
      </ModalFooter>
    
     
 </Modal>
   
    </>
  );
};

export default ManageCategories;
