import { React, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import appConstants from "../appConstants";
import config from '../config/configg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TailSpin } from "react-loader-spinner";
import PhoneInput from 'react-phone-input-2';
import { Modal, ModalHeader,ModalFooter,ModalBody } from "reactstrap";

const BACKEND_URL = config.BACKEND_URL;

const AddNewExpert = () => {
  const history = useNavigate();
  const [expertcredential, setexpertcredential] = useState({
    category: "623ac633ea73cd5cb8dc2f8e",
    practiceArea: "623ac8318be1c0470cd82b7c",
    bio: "",
    audioFilePath: "dfsa",
    videoFilePath: "dfsafdsa",
    document: [
      {
        fileName: "dsfadfs",
        fileType: "dsfasd",
        link: "sdfadfs",
        mimeType: "afdsdfsa",
      },
    ],
    firstName: "",
    email: "",
    // mobileNo: 0,
    lastName: "",
    profilePic: "dsfadfsdfs",
    otp: "",
    experience: 0,
  });
  const [mainLoader,setMainLoader]=useState(false);
  const [getProfilePic, setGetProfilePic] = useState("");
  const [getAudioFilePath, setGetAudioFilePath] = useState("");
  const [getVideoFilePath, setGetVideoFilePath] = useState("");
  const [getDocument, setGetDocument] = useState([
    { fileName: "", fileType: "", link: "", mimeType: "" },
  ]);
  const [getPracticeArea, setGetPracticeArea] = useState([]);
  const [getCategories, setGetCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedPracticeArea, setSelectedPracticeArea] = useState(0);
  const [selectedAccountType, setSelectedAccountType] = useState("expert");
  const [mobileNo,setMobileNumber]=useState("")
  const [inputKey,setInputKey]=useState('');
  const fetchAllCategories = async () => {
    // console.log("this is categories" ,selectedCategory )
    const response = await fetch(
      `${BACKEND_URL}/admin/getCategoriesData`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const jsonnn = await response.json();
    console.log("satyamtomar", jsonnn);
    setGetCategories(jsonnn.data);
    console.log("sdflkjsdfkjlda", getCategories);
  };
useEffect(()=>{
  fetchAllPracticeArea();
},[selectedCategory])
  const fetchAllPracticeArea = async () => {
    // selectedCategory
    console.log("this is cate" ,selectedCategory )
    const response = await fetch(
      `${BACKEND_URL}/admin/getPracticeAreaData?selectedCategory=${selectedCategory}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const jsonnn = await response.json();
    console.log(jsonnn);
    setGetPracticeArea(jsonnn.data);
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      // fetchAllPracticeArea();
      fetchAllCategories();
    } else {
      history("/login");
    }

    // eslint-disable-next-line
  }, []);
  const onSubmitCreateExpert = async () => {
    setMainLoader(true);

    const {
      category,
      practiceArea,
      bio,
      audioFilePath,
      videoFilePath,
      document,
      firstName,
      email,
      // mobileNo,
      lastName,
      profilePic,
      experience,
      otp,
    } = expertcredential;
    if(!firstName && !lastName && !experience && !bio && !experience && !getProfilePic && !mobileNo && !selectedCategory && !selectedPracticeArea)
    {
      toast('Please Fill All Details');

      setMainLoader(false)
    }
    // console.log(expertcredential);
    if(firstName.length<1)
    {
      toast('Enter valid firstName');
      setMainLoader(false)

      return;
    }
    else if(lastName.length<1)
    {
      toast('Enter valid lastName');
      setMainLoader(false)

      return;
    }
    else if(mobileNo.length<10)
    {
      toast('Enter Valid mobileNo');
      setMainLoader(false)

      return;
    }
    else if(email.length<5)
    {
      // console.log(email.length,'length of email')
      toast('enter a valid email');
      setMainLoader(false)

      return;
    }

    const response = await fetch(
      `${BACKEND_URL}/admin/createexpertuserbyadmin`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),

          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          mobileNo,
          profilePic: getProfilePic,
          document: getDocument,
          category: selectedCategory,
          practiceArea: selectedPracticeArea,
          bio,
          audioFilePath: getAudioFilePath,
          videoFilePath: getVideoFilePath,
          accountType: selectedAccountType,
          otp: "123456",
          experience: experience,
        }),

        

      }
    );
    const json = await response.json();
    setMainLoader(false);
     if(json.statusCode===200)
     {
       toast('Expert Created Successfully');
       setexpertcredential({
        category: "623ac633ea73cd5cb8dc2f8e",
        practiceArea: "623ac8318be1c0470cd82b7c",
        bio: "",
        audioFilePath: "dfsa",
        videoFilePath: "dfsafdsa",
        document: [
          {
            fileName: "dsfadfs",
            fileType: "dsfasd",
            link: "sdfadfs",
            mimeType: "afdsdfsa",
          },
        ],
        firstName: "",
        email: "",
        // mobileNo: "",
        lastName: "",
        profilePic: "dsfadfsdfs",
        otp: "",
        experience: '',
        
      });
      setMobileNumber("");
      setGetProfilePic("");
        setGetAudioFilePath("");
        setGetVideoFilePath("");
        setGetDocument([
          { fileName: "", fileType: "", link: "", mimeType: "" },
        ]);
        setInputKey("change");
        setInputKey('');
        setSelectedCategory(0);
        setSelectedPracticeArea(0);
        setSelectedAccountType("expert");
        history("/expertlisting")
      //  document.getElementById('document').files[0]=null
      //  setexpertcredential();
     }
     else 
     {
       toast(json.message);
       setMainLoader(false);

     }
     setMainLoader(false);

    console.log(json);
  };
  const onChangeExpert = (e) => {
    console.log("this is name", e.target.name)
    setexpertcredential({
      ...expertcredential,
      [e.target.name]: e.target.value,
    });
  };

  const uploadFilesUsingMulter = async (e, i) => {
    console.log("iiiihiihiohiin", "aloha0", e.target.files[0]);
    var formData = new FormData();
    formData.append("files", e.target.files[0]);
    const axiosRes = await axios({
      method: "post",
      url: `${BACKEND_URL}/admin/uploadfile`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
    console.log(i, "idkkkkk", axiosRes);
    if (i === 1) {
      setGetProfilePic(axiosRes.data.data[0].path);
    } else if (i === 2) {
      setGetAudioFilePath(axiosRes.data.data[0].path);
    } else if (i === 3) {
      setGetVideoFilePath(axiosRes.data.data[0].path);
    } else if (i === 4) {
      setGetDocument([
        {
          fileName: axiosRes.data.data[0].filename,
          fileType: axiosRes.data.data[0].filename,
          link: axiosRes.data.data[0].path,
          mimeType: axiosRes.data.data[0].mimetype,
        },
      ]);
    }
  };

  return (
    <>
    <ToastContainer/>
     {mainLoader ? (
          <div className="custm-loader">
            <TailSpin color="#000" height={200} width={200} />
          </div>
        ) : null}
     
      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex">
                    <h3>Add new Expert</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-short-nav-buttons">
                  <div className="table-btn-group">
                    <Link to="/expertlisting">
                      <button className="btn" type="button">
                        {" "}
                        <i className="fa fa-long-arrow-left me-2"></i> Back
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="basic-info-wrp">
                <div className="admin-white-box p-0">
                  <div className="basic-info-header">
                    <h5>Add expert</h5>
                  </div>

                  <div className="form-feilds-container">
                  <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Account Type</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <select
                              className="form-select"
                              onChange={(e) => {
                                setSelectedAccountType(e.target.value);
                              }}
                              value={selectedAccountType}
                            >
                              <option value="expert">Expert</option>
                              <option value="freelancer">Freelancer</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Expert name</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <input
                              type="text"
                              name="firstName"
                              maxlength="10"
                              onChange={onChangeExpert}
                              className="form-control"
                              placeholder="First name"
                              value={expertcredential.firstName}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <input
                              type="text"
                              name="lastName"
                              maxlength="10"
                              onChange={onChangeExpert}
                              className="form-control"
                              placeholder="Last name"
                              value={expertcredential.lastName}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Category</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <select
                              className="form-select"
                              onChange={(e) => {
                                // console.log("this is cate" , e.target.value)
                                setSelectedCategory(e.target.value
                                 
                                );
                                
                              }}
                              value={selectedCategory}
                            >
                              <option selected="" value="0">
                                Select Category
                              </option>
                              {getCategories &&
                                getCategories.map((obj, index) => {
                                  return (
                                    <option value={`${obj._id}`}>
                                      {obj.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <Link to="/addcategories">Add new Category</Link>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Practice area</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <select
                              className="form-select"
                              onChange={(e) => {
                                setSelectedPracticeArea(e.target.value);
                              }}
                              value={selectedPracticeArea}
                            >
                              <option value={0}>Select Practice area</option>
                              {getPracticeArea &&
                                getPracticeArea.map((obj, index) => {
                                  return (
                                    <option value={`${obj._id}`}>
                                      {obj.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <Link to="/addpractice">Add new Practice area</Link>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Experience</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <input
                              type="text"
                              name="experience"
                              value={expertcredential.experience}
                              onChange={onChangeExpert}
                              className="form-control"
                              placeholder="Expert experience"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              maxLength='2'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                   <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Contact number</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            {/* <input
                              type="text"
                              name="mobileNo"
                              onChange={onChangeExpert}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              
                              value={expertcredential.mobileNo}
                             maxLength="14"
                              className="form-control"
                              placeholder="Enter mobile number"
                            /> */}
                          <PhoneInput
                          country={'OM'}
                            type="text"
                            class="form-control"
                            id="mobileNo"
                            name="mobileNo"

                            // name="mobileNumber"
                            onChange={(e)=>{
                              setMobileNumber(e)
                            }
                              
                            }
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            
                            value={mobileNo}
                            maxLength="14"

                            placeholder="Enter your Mobile Number"
                          />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Email Id</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              value={expertcredential.email}
                              onChange={onChangeExpert}
                              className="form-control"
                              placeholder="Enter Email ID"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Upload Document </h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <input
                              type="file"
                              id='document'
                              accept="image/png,image/jpeg,image/jpg"
                              key={inputKey}
                              onChange={(e) => {
                                uploadFilesUsingMulter(e, 4);
                              }}
                              className="form-control"
                              placeholder="Attach Document"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row mb-5">
                      <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3">
                          <button
                            className="btn btn-grey-common"
                            type="submit"
                            onClick={onSubmitCreateExpert}
                          >
                            Add Expert
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer/>
    </>
  );
};

export default AddNewExpert;
