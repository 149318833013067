import Agent from "./super.js";
import config from "../config/configg";
import { ServerError } from "../utils/helpers";
const BACKEND_URL = config.BACKEND_URL;

function getAdminDetails(cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/getAdminDetails`).end((err, res) => {
    var error =
      err || res.error
        ? ServerError(res)
        : res.body && res.body.error
        ? ServerError(res)
        : null;
    if (typeof cb === "function") return cb(error, res && res.body);
  });
}

export default {
  getAdminDetails,
};
