import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Creatable from "react-select/creatable";

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import config from '../config/configg';
const BACKEND_URL = config.BACKEND_URL;

const AddFirm = () => {
  const history = useNavigate();
  const [contactNo, setContactNo] = useState("")
  const [firmName, setFirmName] = useState("")
  const [firmEmail, setFirmEmail] = useState("")
  const [contactPerson, setContactPerson] = useState("")

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  // const [cities, setCities] = useState([]);
  const [countryCode, setCountryCode] = useState('OM');
  const [country, setCountry] = useState('Oman');


  const [stateCode, setStateCode] = useState('')
  const [state, setState] = useState('')
  const [selectedCountry, setSelectedCountry] = useState({label:"Oman",value:"OM"});
  const [selectedState, setSelectedState] = useState(null);
  // const [selectedCity, setSelectedCity] = useState(null);
  const [city, setCity] = useState(null)

  const [contactLandLine, setLandlineNo] = useState("")
  const [zipcode, setZipcode] = useState("")
  const [area, setArea] = useState("")
  const [streetNo, setStreetNo] = useState("")
  const [companyRegNo, setCompanyRegNo] = useState("")

  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("token")) {
    } else {
      history("/login");
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadCountries();
    // loadStates()
  }, []);


  const handleChange = async (event) => {
    // if (event.target.name === "contactNo") {
    //   setContactNo(event.target.value)
    // }
    if (event.target.name === "firmName") {
      setFirmName(event.target.value)
    }
    if (event.target.name === "firmEmail") {
      setFirmEmail(event.target.value)
    }
    if (event.target.name === "contactPerson") {
      setContactPerson(event.target.value)
    }
    if (event.target.name === "companyRegNo") {
      setCompanyRegNo(event.target.value)
    }
    if (event.target.name === "password") {
      setPassword(event.target.value)
    }
    if (event.target.name === "zipcode") {
      setZipcode(event.target.value)
    }
    if (event.target.name === "address1") {
      setStreetNo(event.target.value)
    }
    if (event.target.name === "address2") {
      setArea(event.target.value)
    }
    if (event.target.name === "landLineNo") {
      setLandlineNo(event.target.value)
    }
    if(event.target.name === "city"){
      setCity(event.target.value)
    }

  }
  const handlePhoneChange = async (event) => {
    setContactNo(event)
  }

  const handlePageReset = async () => {

    setContactNo("")

    setFirmName("")

    setFirmEmail("")

    setContactPerson("")

    setCompanyRegNo("")

    setPassword("")

    setStreetNo("")

    setArea("")

    setCity("")

    setCountry("")

    setLandlineNo("")

    setState("")

    setZipcode("")

  }

  //
  const loadCountries = async () => {

    const response = await fetch(
      `${BACKEND_URL}/common/countries`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );
    const jsonnn = await response.json();
    let countriesData = [];
    for (let country of jsonnn.data) {
      countriesData.push({
        value: country.isoCode,
        label: country.name,
      });
    }
    setCountries(countriesData);
    countriesData = null;

  };

  const loadStates = async () => {
    const queryParams = {
      countryCode: countryCode,
    };

    const queryString = new URLSearchParams(queryParams).toString();

    const response = await fetch(
      `${BACKEND_URL}/common/states?${queryString}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    const jsonnn = await response.json();

    let statesData = [];
    for (let state of jsonnn.data) {
      statesData.push({
        value: state.isoCode,
        label: state.name,
      });
    }
    setStates(statesData);
    statesData = null;

  };

  const loadCities = async () => {

    const queryParams = {
      countryCode: countryCode,
      stateCode: stateCode,
    };

    const queryString = new URLSearchParams(queryParams).toString();


    const response = await fetch(
      `${BACKEND_URL}/common/cities?${queryString}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    const jsonnn = await response.json();

    // let citiesData = [];
    // for (let city of jsonnn.data) {
    //   citiesData.push({
    //     value: city.name,
    //     label: city.name,
    //   });
    // }
    // setCities(citiesData);
    // citiesData = null;

  };

  const handleStateChange = async (event) => {
    setStateCode(event.value);
    setState(event.label);
    setSelectedState(event);

    // setCity('');
    // setSelectedCity(null);
    // if (event.__isNew__ === undefined) {
    //   await loadCities();
    // } else {
    //   setCity('');
    //   setSelectedCity(null);
    // }
  };

  const handleCountryChange = async (event) => {
    setCountryCode(event.value);
    setCountry(event.label);
    
    setSelectedCountry(event);
    setState('');
    setSelectedState(null);
    // setCity('');
    // setSelectedCity(null);
    
  };

  useEffect(() => {
    loadStates()
  }, [countryCode]);

  useEffect(() => {
    loadCities()
  }, [stateCode]);


  // const handleCityChange = async (event) => {
  //   setSelectedCity(event);
  //   setCity(event.label);
  // };

  const addFirm = async () => {

    let addedBy = localStorage.getItem('user')
    const addedUserObj = JSON.parse(addedBy)

     addedBy = addedUserObj._id

    let dataToSend = { contactNo, firmName, firmEmail, contactPerson, companyRegNo, password, city, state, country, streetNo, area, zipcode, contactLandLine,addedBy }
    const response = await fetch(
      `${BACKEND_URL}/firm/addFirm`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),


        },
        body: JSON.stringify(dataToSend)
      }
    );
    const jsonnn = await response.json();
    if (jsonnn.statusCode === 200) {
      toast(jsonnn.message)
      handlePageReset()
      history("/managefirm")
    } else {
      toast(jsonnn.message)
    }

  };

  return (
    <>
      <ToastContainer />
      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex">
                    <h3>Add Firm</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-short-nav-buttons">
                  <div className="table-btn-group">
                    <Link to="/managefirm">
                      <button className="btn" type="button">
                        {" "}
                        <i className="fa fa-long-arrow-left me-2"></i> Back
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="basic-info-wrp">
                <div className="admin-white-box p-0">
                  <div className="basic-info-header">
                    <h5>Add new firm</h5>
                  </div>

                  <div className="form-feilds-container">
                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
      <h3>Firm name<span className="required">*</span></h3>
    </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Firm name"
                              name="firmName"
                              value={firmName}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Company Registration <span className="required">*</span></h3>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Number"
                              name="companyRegNo"
                              value={companyRegNo}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Contact person<span className="required">*</span></h3>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Contact person of firm"
                              name="contactPerson"
                              value={contactPerson}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*  */}
                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Landline Number</h3>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="landLineNo"
                              name="landLineNo"
                              value={contactLandLine}
                              onChange={handleChange}
                              placeholder="Company Landline No."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Address</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="address1"
                              name="address1"
                              value={streetNo}
                              onChange={handleChange}
                              placeholder="Street No, Block No."
                            />
                          </div>
                        </div>

                        <div className="col-lg-3">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="address2"
                              name="address2"
                              value={area}
                              onChange={handleChange}
                              placeholder="Area"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Country</h3>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <Creatable
                              onChange={handleCountryChange}
                              placeholder="Select Country"
                              value={selectedCountry}
                              options={countries}
                            />
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>State,City</h3>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <Creatable
                              onChange={handleStateChange}
                              placeholder="Select State"
                              value={selectedState}
                              options={states}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            {/* <Creatable
                              onChange={handleCityChange}
                              placeholder="Select City"
                              value={selectedCity}
                              options={cities}
                            /> */}
                            <input
                              type="text"
                              className="form-control"
                              id="city"
                              name="city"
                              value={city}
                              onChange={handleChange}
                              placeholder="City"
                            />
                          </div>
                        </div>

                        <div className="col-lg-3">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="zipcode"
                              name="zipcode"
                              value={zipcode}
                              onChange={handleChange}
                              placeholder="Zipcode"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Company Registration Number</h3>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="crNo"
                              name="crNo"
                              // value={landlineNo}
                              onChange={handleChange}
                              placeholder="Company Registration No."
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}

                    {/*  */}
                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Contact number<span className="required">*</span></h3>
                        </div>
                        <div className="col-lg-4">
                          {/* <div className="form-group"> */}
                          {/* <input
                              type="text"
                              className="form-control"
                              placeholder="Enter mobile number"
                              name="contactNo"
                              value={contactNo}
                              onChange={handleChange}
                            /> */}
                          <PhoneInput
                            defaultCountry={selectedCountry.value ||"OM"}
                            className="form-control"
                            id="mobileNo"
                            value={contactNo}
                            name="contactNo"
                            onChange={handlePhoneChange}
                            placeholder="Enter mobile number"
                            
                          />
                          {/* </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Email ID<span className="required">*</span></h3>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter Email ID"
                              name="firmEmail"
                              value={firmEmail}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Password<span className="required">*</span></h3>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group input-group">
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              placeholder="Enter Password"
                              name="password"
                              value={password}
                              onChange={handleChange}
                              required
                            />
                            <span className="input-group-text" onClick={() => { setShowPassword(!showPassword) }}>{showPassword ? "Hide" : "Show"}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row mb-5">
                      <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3">
                          <button className="btn btn-grey-common" type="submit" onClick={() => {
                            addFirm()
                          }}>
                            Add Firm
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddFirm;
