import logo from "./logo.svg";
import "./App.css";
import { useContext, useState, useEffect } from "react";
import AddCategories from "./components/AddCategories";
import AddFirm from "./components/AddFirm";
import SubscriptionHistory from "./components/SubscriptionHistory";
import AddFreelancer from "./components/AddFreelancer";
import AddNewExpert from "./components/AddNewExpert";
import AddPractice from "./components/AddPractice";
import AddSubscription from "./components/AddSubscription";
import AddLegalSubscription from "./components/AddLegalSubscription";
import ExpertListing from "./components/ExpertListing";
import ExpertRequest from "./components/ExpertRequest";
import Home from "./components/Home";
import ManageCategories from "./components/ManageCategories";
import ManageFirm from "./components/ManageFirm";
import ManageFreelancer from "./components/ManageFreelancer";
import ManagePractice from "./components/ManagePractice";
import ManageSubscription from "./components/ManageSubscription";
import ManageLegalSubscription from "./components/ManageLegalSubsciption";
import EditSubscription from "./components/EditSubscription";
import EditLegalSubscription from "./components/EditLegalSubscription";
import SideNavbar from "./components/SideNavbar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login";
import EditExpert from "./components/EditExpert";
import EditCategory from "./components/EditCategory";
import EditPracticeArea from "./components/EditPracticeArea";
import QueAndAns from "./components/QueAndAns";
import AddQndA from "./components/addQuesAndAns";
import EditQndA from "./components/editfrequentlyQuestions";
import Testimony from "./components/Testimony";
import UserRequest from "./components/UserRequest";
import loginAction from "./actions/loginAction";
import AddTestimony from "./components/AddTestimony";
import NewsLetter from "./components/NewLetter";
import EditFirm from "./components/EditFirm";
import { AuthContext } from "./context/AuthContext";
import "./assets/css/bootstrap.min.css";
import "./assets/css/main.css";
import "./assets/css/responsive.css";
import ChangePassword from "./components/ChangePassword";
import Logs from "./components/Logs";
import RecordPayment from "./components/RecordPayment";
// import './assets/js/bootstrap.min.js';
// import './assets/js/bootstrap.bundle.min.js'
// import './assets/js/main.js';
function App() {
  const { isLoggedIn, setIsLoggedIn, loggedInAdminInfo, setLoggedInAdminInfo } =
    useContext(AuthContext);
  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      
      loginAction.getAdminDetails((err, res) => {
        if (err) {
          setIsLoggedIn(false);
          // localStorage.removeItem('token');
        } else {
          //   setGetCategories(res.data);
          //   console.log(res.data,"user details daata ");
          // setUserDetails(  res.data  );
          setIsLoggedIn(true);
          setLoggedInAdminInfo(res.data);
          //   setGetProfilePic(res.data.profilePic)
        }
      });
    }
  }, [isLoggedIn]);

  return (
    <>
      <Router>
        <SideNavbar />
        <Routes>
          {isLoggedIn ? (
            // User is logged in
            <Route>
              <Route path="/home" element={<Home />} />
              <Route path="/addcategories" element={<AddCategories />} />
              <Route path="/addfirm" element={<AddFirm />} />
              <Route path="/subscriptionHistory" element={<SubscriptionHistory />} />
              <Route path="/changepassword" element={<ChangePassword />} />
              <Route path="/addfreelancer" element={<AddFreelancer />} />
              <Route path="/addnewexpert" element={<AddNewExpert />} />
              <Route path="/addpractice" element={<AddPractice />} />
              <Route path="/addsubscription" element={<AddSubscription />} />
              <Route path="/AddlegalSubscription" element={<AddLegalSubscription />} />
              <Route path="/RecordPayment" element={< RecordPayment />} />
              <Route path="/editLegalSubscription/:subscriptionId" element={<EditLegalSubscription />} />
              <Route path="/ManagelegalSubscription" element={<ManageLegalSubscription />} />
              <Route path="/get-logs" element={<Logs />} />
              <Route path="/expertlisting" element={<ExpertListing />} />
              <Route path="/expertrequest" element={<ExpertRequest />} />
              <Route path="/managecategories" element={<ManageCategories />} />
              <Route path="/managefreelancer" element={<ManageFreelancer />} />
              <Route path="/managefirm" element={<ManageFirm />} />
              <Route path="/managepractice" element={<ManagePractice />} />
              <Route path="/QandAns" element={<QueAndAns />} />
              <Route path="/AddQandAns" element={<AddQndA />} />
              <Route path="/editQandAns/:id" element={<EditQndA />} />
              <Route path="/editFirm/:firmId" element={<EditFirm />} />
              <Route path="/testimonies" element={<Testimony />} />
              <Route path="/userRequests" element={<UserRequest />} />
              <Route path="/managesubscription" element={<ManageSubscription />} />
              <Route path="/expertlisting/editexpert/:expertId" element={<EditExpert />} />
              <Route path="/managecategories/editcategory/:categoryId" element={<EditCategory />} />
              <Route path="/managepractice/editpracticearea/:practiceId" element={<EditPracticeArea />} />
              <Route path="/editSubscription/:subscriptionId" element={<EditSubscription />} />
              <Route path="/addTestimony" element={<AddTestimony />} />
              <Route path="/addTestimony/:id" element={<AddTestimony />} />
              <Route path="/newsLetter" element={<NewsLetter />} />
              {/* Add other routes for logged-in users */}
              <Route path="*" element={<Navigate to="/managefirm" />} />
            </Route>
          ) : (
            // User is not logged in
            <>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Navigate to="/home" />} />
              <Route path="/home" element={<Home />} />
            </>
          )}
        </Routes>
      </Router>
      {/* <script src="./assets/js/bootstrap.bundle.min.js"></script> */}

      {/* <script src="./assets/js/bootstrap.min.js"></script>
   <script src="./assets/js/main.js"></script> */}
      {/* <script src="./assets/js/bootstrap.min.js"></script>
   <script src="./assets/js/main.js"></script> */}
    </>
  );
}

export default App;
