import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config/configg';
import axios from "axios";
const BACKEND_URL = config.BACKEND_URL;

const ChangePassword = () => {
  const history = useNavigate();
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("token")) {
    } else {
      history("/login");
    }

    // eslint-disable-next-line
  }, []);

  const handleChange = async (event) => {

    if (event.target.name === "oldpassword") {
        setOldPassword(event.target.value)
    }
    if (event.target.name === "newpassword") {
        setNewPassword(event.target.value)
    }
     }

//   const handlePageReset = async () => {
//     setOldPassword("")
//     setNewPassword("")
 
//   }

 
  const changePwd = async()=>{
try{

    console.log(oldPassword,newPassword)
    if(!oldPassword){
        alert('Please enter old password')
        return
    }
    else if(!newPassword){
        alert('Please enter old password')
        return
    }

    let dataToSend = { oldPassword,newPassword }



    const response = await fetch(`${BACKEND_URL}/admin/change-password`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
  
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
            oldPassword,
            newPassword
        }),
      });
      const json = await response.json();
      console.log(json,"jsssssss")

      if(json.statusCode===200)
      {
        toast('Password changed successfully')
      }
      else{
        toast(json.message)
      }  


    // const response = axios.post(`${BACKEND_URL}/firm/change-password`,dataToSend)
    // const axiosRes = await axios(`${BACKEND_URL}/admin/change-password`,{
    //     method: "PATCH",
    //     headers: {
    //       "Content-Type": "application/json",
    //       "auth-token": localStorage.getItem("token"),
  
    //       'Content-Type': 'application/x-www-form-urlencoded',
    //     },data:dataToSend
    // })
    // console.log('axiosRes' , axiosRes)

    // if(axiosRes?.data?.error){
    //     toast(axiosRes?.data?.message)
    // }else{
    //     alert('Password change successfully')
    // }
}
catch(error){
    console.log(error.response.data.message,"errorerrorerrorerror")
    toast(error.response.data.message)
}
      
  }

  return (
    <>
      <ToastContainer />
      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex">
                    <h3>Change Password</h3>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6">
                <div className="admin-short-nav-buttons">
                  <div className="table-btn-group">
                    <Link to="/managefirm">
                      <button className="btn" type="button">
                        {" "}
                        <i className="fa fa-long-arrow-left me-2"></i> Back
                      </button>
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="basic-info-wrp">
                <div className="admin-white-box p-0">
                  <div className="basic-info-header">
                    <h5>Change Your Password</h5>
                  </div>

                  <div className="form-feilds-container">
                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Old Password</h3>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Enter Old Password"
                              name="oldpassword"
                              value={oldPassword}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-fields-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>New Password</h3>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              placeholder="Enter New Password"
                              name="newpassword"
                              value={newPassword}
                              onChange={handleChange}
                            />
                            
                            {/* <span class="input-group-text" onClick={() => { setShowPassword(!showPassword) }}>{showPassword ? "Hide" : "Show"}</span> */}

                          </div>
                        </div>
                      </div>
                    </div>

                   
                    <div className="form-fields-row mb-5">
                      <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3">
                          <button className="btn btn-grey-common" type="submit" onClick={() => {
                            changePwd()
                          }}>
                            Change Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChangePassword;
