import moment from "moment";
import { React, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import config from '../config/configg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, ModalHeader,ModalFooter,ModalBody } from "reactstrap";
import { TailSpin } from "react-loader-spinner";
const BACKEND_URL = config.BACKEND_URL;

const ManagePractice = () => {
  const history = useNavigate();
  const [getPracticeArea, setGetPracticeArea] = useState([]);
  const [search, setSearch] = useState("");
  const [deleteModal,setDeleteModal]=useState(false)
  const [getCategories, setGetCategories] = useState([]);
  const [practiceToBeDeleted,setPracticeToBeDeleted]=useState([]);
  const [loading,setLoading]=useState(true)
  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchAllPracticeAreaPopulated();
    } else {
      history("/login");
    }

    // eslint-disable-next-line
  }, [search]);
  const fetchAllPracticeAreaPopulated = async () => {
    setLoading(true)
    const response = await fetch(
      `${BACKEND_URL}/admin/getPracticeAreaDataPopulated?search=${search}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const jsonnn = await response.json();
    console.log(jsonnn);
    setGetPracticeArea(jsonnn.data);
    setLoading(false)

    console.log("all my practice areas are", jsonnn.data);
  };
  const handleDeletePracticeArea = async (obj) => {
    setLoading(true)
    const response = await fetch(
      `${BACKEND_URL}/admin/deletePracticeArea/${obj._id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const jsonnn = await response.json();
    console.log(jsonnn, "deleted practice area is");
    if(jsonnn.statusCode===200)
    {
      
    setLoading(false)

    fetchAllPracticeAreaPopulated();

    toast('Practice Area has been successfully deleted');
    setDeleteModal(false);
    }
  };
  const searchData = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      {loading ? (
          <div className="custm-loader">
            <TailSpin color="#000" height={200} width={200} />
          </div>
        ) : null}
      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex">
                    <h3>Manage Practice Area</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-short-nav-buttons">
                  <div className="table-btn-group">
                    <Link to="/addpractice">
                      <button className="btn black-fill" type="button">
                        Add Practice Area
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="admin-filter-navbar bg-grey">
            <div className="row align-items-center justify-content-end">
              <div className="col-lg-3">
                <div className="filter-input-box">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Practice Area"
                    onChange={searchData}
                  />
                  <img src="./assets/img/search-icon.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="admin-white-box">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="common-table-wrapper mt-0">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Practice area name</th>
                              <th>Categories </th>
                              <th>Created Date </th>
                              <th> &nbsp; </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/*                                    
                                     <tr>
                                        <td>
                                           <h5>Legal</h5>
                                        </td>
                                        <td>
                                             <ul className="legal-badge-list">
                                                 <li><span>Legal 1</span></li>
                                                 <li><span>Legal 1</span></li>
                                                 <li><span>Legal 1</span></li>
                                                 <li><span>Legal 1</span></li>
                                                 <li><span>Legal 1</span></li>
                                                 <li><span>Legal 1</span></li>
                                                 <li><span>Legal 1</span></li>
                                                 <li><span>Legal 1</span></li>
                                                 <li><Link to="javascript:;"> <i className="fa fa-pencil"></i> Edit</Link></li>
                                             </ul>
                                        </td>
                                        
                                       
                                        <td>
                                           <h6>Sept 14 2021
                                              <br/> By Aprzz James
                                           </h6>
                                        </td>
                                        <td>
                                           <div className="action-btn-group">
                                              <Link to="javascript:;"><button className="btn" type="button"> <img
                                                       src="./assets/img/eye-icon-black.png" alt="" /> </button></Link>
                                              <Link to="javascript:;"><button className="btn" type="button"> <img
                                                       src="./assets/img/edit-pencil-icon.png" alt="" /> </button></Link>
                                              <Link to="javascript:;"><button className="btn" type="button"> <img
                                                       src="./assets/img/delete-icon.png" alt="" /> </button></Link>
                                           </div>
                                        </td>
                                     </tr> */}

                            {getPracticeArea &&
                              getPracticeArea.map((obj, index) => {
                                return (
                                  <>
                                    <tr>
                                      <td>
                                        <h5>{obj.name}</h5>
                                      </td>
                                      <td>
                                        {/* <ul className="legal-badge-list">
                                                 <li><span>Legal 1</span></li>
                                                 <li><span>Legal 1</span></li>
                                                 <li><span>Legal 1</span></li>
                                                 <li><span>Legal 1</span></li>
                                                 <li><span>Legal 1</span></li>
                                                 <li><span>Legal 1</span></li>
                                                 <li><span>Legal 1</span></li>
                                                 <li><span>Legal 1</span></li>
                                                 <li><Link to="javascript:;"> <i className="fa fa-pencil"></i> Edit</Link></li>
                                             </ul> */}
                                        <ul className="legal-badge-list">
                                          <li>
                                            <span>{obj?.categoryId?.name}</span>
                                          </li>
                                        </ul>
                                      </td>

                                      <td>
                                        <h6>
                                          {moment(obj.createdAt).format(
                                            "MMMM Do YYYY, h:mm:ss a"
                                          )}
                                          {/* {obj.createdAt} */}
                                          <br /> By {obj?.createdBy}
                                        </h6>
                                      </td>
                                      <td>
                                        <div className="action-btn-group">
                                          <Link
                                            to={`/managepractice/editpracticearea/${obj._id}`}
                                          >
                                            <button
                                              className="btn"
                                              type="button"
                                            >
                                              {" "}
                                              <img
                                                src="./assets/img/edit-pencil-icon.png"
                                                alt=""
                                              />{" "}
                                            </button>
                                          </Link>
                                          <a>
                                            <button
                                              className="btn"
                                              type="button"
                                              onClick={() => {
                                                // handleDeletePracticeArea(obj);
                                                setDeleteModal(true);
                                                setPracticeToBeDeleted(obj);
                                              }}
                                            >
                                              {" "}
                                              <img
                                                src="./assets/img/delete-icon.png"
                                                alt=""
                                              />{" "}
                                            </button>
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer/>
      <Modal isOpen={deleteModal} toggle={() => setDeleteModal(false)} className='  modal-dialog modal-dialog-centered '>
      
  
      <ModalHeader>
       <h5 class="modal-title" id="exampleModalLabel">Borhan </h5>
       
        </ModalHeader>
      <ModalBody>
        Please Confirm to delete Practice
      </ModalBody>
      <ModalFooter>
        <button type="button" class="btn btn-secondary" onClick={()=>{setDeleteModal(false);}}>Close</button>
        <button type="button" class="btn btn-primary" onClick={()=>handleDeletePracticeArea(practiceToBeDeleted)}>Delete Practice Area</button>
      </ModalFooter>
    
     
 </Modal>
   
    </>
  );
};

export default ManagePractice;
