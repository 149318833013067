import { React, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import config from '../config/configg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import html2PDF from 'jspdf-html2canvas';
import jsPDF from "jspdf";
import logoImage from '../assets/img/main-logo.png'

const BACKEND_URL = config.BACKEND_URL;


const ManageFirm = () => {
  const [firmData, setFirmData] = useState([])
  const [search, setSearch] = useState('')
  const tableRef = useRef(null)

  const [searchFirm, setSearchFirm] = useState('')
  const [searchPerson, setSearchPerson] = useState('')
  const [searchNumber, setSearchNumber] = useState('')
  const [searchEmail, setSearchEmail] = useState('')

  const history = useNavigate();
  const [currentPage, setCurrentPage] = useState(1)
  const [showTable, setShowTable] = useState(false)
  const [paginationData, setPaginationData] = useState({
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 0,
    offset: 0,
    page: 0,
    pagingCounter: 0,
    totalDocs: 0,
    totalPages: 0,
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getAllFirms()
    } else {
      history("/login");
    }

    // eslint-disable-next-line
  }, [searchFirm, searchPerson, searchNumber, searchEmail, currentPage]);

  const getAllFirms = async () => {

    const response = await fetch(
      `${BACKEND_URL}/firm/getAllFirms?page=${currentPage}&searchFirm=${searchFirm}&searchPerson=${searchPerson}&searchNumber=${searchNumber}&searchEmail=${searchEmail}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        }
      }
    );
    const jsonnn = await response.json();
    if (jsonnn.statusCode === 200) {
      setPaginationData(jsonnn.data)
      setFirmData(jsonnn.data.docs)
    } else {
      toast(jsonnn.message)
    }

  };
  const handlePageClick = (data) => {
    let current = data.selected + 1;
    setCurrentPage(current)
  };

  useEffect(() => {
    if (showTable) {
      generatePDF()
      setShowTable(false)
    }
  }, [showTable])

  const generatePDF = async () => {
    let page = document.getElementsByClassName('showNone');

    const pdf = await html2PDF(page, {
      jsPDF: {
        format: 'a4',
      },
      imageType: 'image/jpeg',
      output: 'Subscription_history',
    });
  }
  
  return (
    <>
      <ToastContainer />
      <section className="admin-wrapper">
        <div className="admin-content-wrapper">
          <div className="admin-title-header mt-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <div className="admin-title-flex">
                    <h3>Manage Firm</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-short-nav-buttons">
                  <div className="text-end d-flex align-items-center justify-content-end">
                    <DownloadTableExcel
                      filename={`Manage_firm`}
                      sheet="Manage_firm"
                      currentTableRef={tableRef.current}
                    >
                      <button className="btn btn-blue"> Export excel </button>

                    </DownloadTableExcel>
                    <button className="btn btn-blue ms-2" onClick={e => setShowTable(true)} type="button">Export PDF</button>
                    <Link to="/addfirm">
                      <button className="btn black-fill btn-primary ms-2" type="button">
                        Add New Firm
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="admin-filter-navbar bg-grey">
            <div className="row align-items-center">

              {/*hi  */}
              <div className="col-sm">
                <div className="filter-input-box">
                  <label style={{ width: '100%' }}>
                    Firm Name
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Firm"
                      value={searchFirm}
                      onChange={(e) => { setCurrentPage(1); setSearchFirm(e.target.value); }}
                    />
                  </label>
                </div>
              </div>
              <div className="col-sm">
                <div className="filter-input-box">
                  <label style={{ width: '100%' }}>
                    Contact Person
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Person"
                      value={searchPerson}
                      onChange={(e) => { setCurrentPage(1); setSearchPerson(e.target.value); }}
                    />
                  </label>
                </div>
              </div>
              <div className="col-sm">
                <div className="filter-input-box">
                  <label style={{ width: '100%' }}>
                    Contact Number
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Number"
                      value={searchNumber}
                      onChange={(e) => { setCurrentPage(1); setSearchNumber(e.target.value); }}
                    />
                  </label>
                </div>
              </div>
              <div className="col-sm">
                <div className="filter-input-box">
                  <label style={{ width: '100%' }}>
                    Email ID
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Email"
                      value={searchEmail}
                      onChange={(e) => { setCurrentPage(1); setSearchEmail(e.target.value); }}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="admin-white-box">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="common-table-wrapper mt-0 checkTable">
                      <div className="table-responsive">
                        <table className="table" >
                          <thead>
                            <tr>
                              <th className="hideCols"> &nbsp; </th>
                              <th>Firm name </th>
                              <th>Contact person </th>
                              <th>Added by </th>
                              <th>Contact number </th>
                              <th>Email ID </th>
                              <th className="hideCols">Action</th>

                            </tr>
                          </thead>
                          <tbody>
                            {firmData && firmData.length > 0 && firmData.map((el, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <img
                                      src="./assets/img/user-img.png"
                                      className="img img-fluid"
                                      alt=""
                                    />
                                  </td>
                                  <td>
                                    <h5>{el.firmName}</h5>
                                  </td>
                                  <td>
                                    <h5>{el.contactPerson}</h5>
                                  </td>
                                  <td>
                                    <h6>
                                      Admin
                                    </h6>
                                  </td>
                                  <td>
                                    <h6>{el.contactNo}</h6>
                                  </td>
                                  <td>
                                    <h6>{el?.user?.email}</h6>
                                  </td>
                                  <td>

                                    <Link to={`/editFirm/${el?._id}`}>
                                      {/* <button className="btn" type="button"> */}
                                      <img
                                        src="/assets/img/edit-pencil-icon.png"
                                        alt=""
                                      />

                                      {/* </button> */}
                                    </Link>
                                  </td>
                                  {/* hi2 */}
                                </tr>)
                            })}


                          </tbody>
                        </table>
                      </div>
                      <div className="col-sm-12 common-table-wrapper mt-0 showNone" style={{position: 'absolute', display: showTable ? '' : 'none'}}>
                        <div className="table-responsive">
                          <img src={logoImage} style={{display:"block", marginLeft:"auto",marginRight:"auto"}} alt="" />
                          <table className="table1 col-sm-12" ref={tableRef}>
                            <thead>
                              <tr>
                                <th>Firm name </th>
                                <th>Contact person </th>
                                <th>Added by </th>
                                <th>Contact number </th>
                                <th className="text-center">Email ID </th>

                              </tr>
                            </thead>
                            <tbody>
                              {firmData && firmData.length > 0 && firmData.map((el, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <h5>{el.firmName}</h5>
                                    </td>
                                    <td>
                                      <h5>{el.contactPerson}</h5>
                                    </td>
                                    <td>
                                      <h6>
                                        Admin
                                      </h6>
                                    </td>
                                    <td>
                                      <h6>{el.contactNo}</h6>
                                    </td>
                                    <td>
                                      <h6>{el?.user?.email}</h6>
                                    </td>
                                  </tr>)
                              })}


                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {paginationData.docs && paginationData.docs.length > 0 ?
                      <div className="react-paginate-wrapper"><ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={paginationData?.totalPages}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "pagination justify-content-center"
                        }
                        forcePage={paginationData?.page - 1}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      /></div>
                      : <div className="text-center">{"No Data"}</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
};

export default ManageFirm;
