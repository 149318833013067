import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Collapse from "react-bootstrap/Collapse";
const SideNavbar = () => {
  const { isLoggedIn, setIsLoggedIn, loggedInAdminInfo, setLoggedInAdminInfo } =
    useContext(AuthContext);
  const [openFirmCollapse, setOpenFirmCollapse] = useState(false);
  const [openExpertCollapse, setOpenExpertCollapse] = useState(false);
  const [openSubscriptionCollapse, setOpenSubscriptionCollapse] =
    useState(false);
  const [openLegalSubscriptionCollapse, setOpenLegalSubscriptionCollapse] =
    useState(false);
  const [openPracticeCollapse, setOpenPracticeCollapse] = useState(false);
  const [openQuestionCollapse, setOpenQuestionCollapse] = useState(false);
  const [openAdditionalOptions, setOpenAdditionalOptions] = useState(false);
  const [toggleButton, setToggleButton] = useState(false);
  const history = useNavigate();
  const logoutFunction = () => {
    localStorage.clear();
    // console.log(localStorage.getItem('token'));
    setIsLoggedIn(false);
    localStorage.removeItem("token");
    history("/login");
  };
  let location = useLocation();
  const style = {
    fontWeight: "bold",
    color: "blue",
  };
  let render = isLoggedIn ? (
    <div className={`${location.pathname === "/login" ? "d-none" : ""} `}>
      <div className="mobile-nav-toggle">
        <div className="admin-mb-logo">
          <img
            src="./assets/img/main-logo.png"
            className="img img-fluid"
            alt=""
          />
        </div>
        <div className="admin-sidebar-toggle-icon">
          <a
            activeClassName="active"
            activeStyle={style}
            onClick={logoutFunction}
          >
            {" "}
            <button className="btn" type="button">
              <img src="./assets/img/logout-icon.png" alt="" />
            </button>
          </a>
          <button
            className="btn astc-icon"
            type="button"
            onClick={() => setToggleButton(!toggleButton)}
          >
            <img src="./assets/img/navbar-toggle-icon.png" alt="" />
          </button>
        </div>
      </div>

      <div className={`sidebar-wrapper ${toggleButton ? "d-block" : ""}`}>
        <div className="sidebar-main-logo">
          <img src="./assets/img/main-logo.png" alt="" />
        </div>
        <div className="flex-shrink-0 ">
          <ul className="list-unstyled ps-0">
            {/* <li className={`${location.pathname === "/home" ? "active" : ""}`}>
              <Link
                to="/home"
                className={`${location.pathname === "/home" ? "active" : ""}`}
              >
                <button
                  className="btn btn-toggle align-items-center rounded active"
                  onClick={() => setToggleButton(!toggleButton)}
                >
                  User Manage
                </button>
              </Link>
            </li> */}
            <li
              className={`${location.pathname === "/managefirm" ||
                  location.pathname === "/addfirm"
                  ? "active"
                  : ""
                }`}
            >
              <button
                className="btn btn-toggle align-items-center rounded collapsed "
                onClick={() => {
                  setOpenFirmCollapse(!openFirmCollapse);
                }}
                // data-bs-toggle="collapse"
                // data-bs-target="#firm-collapse"
                aria-expanded={openFirmCollapse}
              >
                Firm Management<i className="fa fa-caret-down"></i>
              </button>
              <Collapse in={openFirmCollapse}>
                <div className="collapse" id="firm-collapse">
                  <ul className="btn-toggle-nav">
                    <li
                      className={`${location.pathname === "/managefirm" ? "active" : ""
                        }`}
                    >
                      <Link
                        to="/managefirm"
                        className="link-dark rounded"
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Manage Firm
                      </Link>
                    </li>
                    <li
                      className={`${location.pathname === "/addfirm" ? "active" : ""
                        }`}
                    >
                      <Link
                        to="/addfirm"
                        className="link-dark rounded"
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Add new Firm
                      </Link>
                    </li>
                    
                  </ul>
                </div>
              </Collapse>
            </li>



            <li className={`${location.pathname === "/expertlisting" ||
                location.pathname === "/addnewexpert" ||
                location.pathname === "/changepassword"
                ? "active"
                : ""
              }`}>


<Link
                        to="/changepassword"
                        className="link-dark rounded"
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Change Password
                      </Link>
            
            </li>

            
            <li className={`${location.pathname === "/expertlisting" ||
                location.pathname === "/addnewexpert" ||
                location.pathname === "/get-logs"
                ? "active"
                : ""
              }`}>


<Link
                        to="/get-logs"
                        className="link-dark rounded"
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Logs
                      </Link>
            
            </li>

            {/* <li
              className={`${
                location.pathname === "/expertlisting" ||
                location.pathname === "/addnewexpert" ||
                location.pathname === "/expertrequest"
                  ? "active"
                  : ""
              }`}
            >
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                onClick={() => {
                  setOpenExpertCollapse(!openExpertCollapse);
                }}
                // data-bs-toggle="collapse"
                // data-bs-target="#manexpert-collapse"
                aria-expanded={openExpertCollapse}
              >
                Expert Management<i className="fa fa-caret-down"></i>
              </button>
              <Collapse in={openExpertCollapse}>
                <div className="collapse" id="manexpert-collapse">
                  <ul className="btn-toggle-nav">
                    <li
                      className={`${
                        location.pathname === "/expertlisting" ? "active" : ""
                      }`}
                    >
                      <Link
                        to="/expertlisting"
                        className="link-dark rounded"
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Expert Listing
                      </Link>
                    </li>
                    <li
                      className={`${
                        location.pathname === "/addnewexpert" ? "active" : ""
                      }`}
                    >
                      <Link
                        to="/addnewexpert"
                        className="link-dark rounded"
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Add New Expert
                      </Link>
                    </li>
                    <li
                      className={`${
                        location.pathname === "/expertrequest" ? "active" : ""
                      }`}
                    >
                      <Link
                        to="/expertrequest"
                        className="link-dark rounded"
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Expert Request
                      </Link>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </li>
*/}
            {/* <li
              className={`${
                location.pathname === "/addsubscription" ||
                location.pathname === "/managesubscription"
                  ? "active"
                  : ""
              }`}
            >
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                // data-bs-toggle="collapse"
                // data-bs-target="#case-collapse"
                onClick={() =>
                  setOpenSubscriptionCollapse(!openSubscriptionCollapse)
                }
                aria-expanded={openSubscriptionCollapse}
              >
                Subscription Plans<i className="fa fa-caret-down"></i>
              </button>
              <Collapse in={openSubscriptionCollapse}>
                <div className="collapse" id="case-collapse">
                  <ul className="btn-toggle-nav">
                    <li
                      className={`${
                        location.pathname === "/managesubscription"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link
                        to="/managesubscription"
                        className="link-dark rounded"
                        activeClassName="active"
                        activeStyle={style}
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Manage Subscription
                      </Link>
                    </li>
                    <li
                      className={`${
                        location.pathname === "/addsubscription" ? "active" : ""
                      }`}
                    >
                      <Link
                        to="/addsubscription"
                        className="link-dark rounded"
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Add new Subscription
                      </Link>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </li>  */}
 {/*  */}

            <li
              className={`${
                location.pathname === "/AddlegalSubscription" ||
                location.pathname === "/ManagelegalSubscription" ||
                location.pathname === "/recordpayment" 

                  ? "active"
                  : ""
              }`}
            >
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                // data-bs-toggle="collapse"
                // data-bs-target="#case-collapse"
                onClick={() =>
                  setOpenLegalSubscriptionCollapse(!openLegalSubscriptionCollapse)
                }
                aria-expanded={openLegalSubscriptionCollapse}
              >
                Legal Subscription Plans<i className="fa fa-caret-down"></i>
              </button>
              <Collapse in={openLegalSubscriptionCollapse}>
                <div className="collapse" id="case-collapse">
                  <ul className="btn-toggle-nav">
                  <li
                      className={`${
                        location.pathname === "/recordpayment" ? "active" : ""
                      }`}
                    >
                      <Link
                        to="/recordpayment"
                        className="link-dark rounded"
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Record Payment
                      </Link>
                    </li>
                    <li
                      className={`${
                        location.pathname === "/ManagelegalSubscription"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link
                        to="/ManagelegalSubscription"
                        className="link-dark rounded"
                        activeClassName="active"
                        activeStyle={style}
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Subscription
                      </Link>
                    </li>
                    <li
                      className={`${
                        location.pathname === "/AddlegalSubscription" ? "active" : ""
                      }`}
                    >
                      <Link
                        to="/AddlegalSubscription"
                        className="link-dark rounded"
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Add new Subscription
                      </Link>
                    </li>
                  <li
                      className={`${location.pathname === "/subscriptionHistory" ? "active" : ""
                        }`}
                    >
                      <Link
                        to="/subscriptionHistory"
                        className="link-dark rounded"
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Subscription history
                      </Link>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </li> 
                      {/* 
            {/* <li
        className={`${
          location.pathname === "/managefreelancer" ||
          location.pathname === "/addfreelancer"
            ? "active"
            : ""
        }`}
      >
        <button
          className="btn btn-toggle align-items-center rounded collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#activity-collapse"
          aria-expanded="false"
        >
          Freelancing Management<i className="fa fa-caret-down"></i>
        </button> */}
            {/* <div className="collapse" id="activity-collapse">
          <ul className="btn-toggle-nav">
            <li>
              <Link
                to="/managefreelancer"
                className="link-dark rounded"
              
              >
                Manage Freelancer
              </Link>
            </li>
            <li>
              <Link
                to="/addfreelancer"
                className="link-dark rounded"
                
              >
                Add New Freelancer
              </Link>
            </li>
          </ul>
        </div> */}
            {/* </li> */}
            {/* <li
              className={`${
                location.pathname === "/managepractice" ||
                location.pathname === "/addpractice" ||
                location.pathname === "/managecategories" ||
                location.pathname === "/addcategories"
                  ? "active"
                  : ""
              }`}
            >
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                // data-bs-toggle="collapse"
                // data-bs-target="#financial-collapse"
                onClick={() => {
                  setOpenPracticeCollapse(!openPracticeCollapse);
                }}
                aria-expanded={openPracticeCollapse}
              >
                Manage Practice Area’s
                <i className="fa fa-caret-down"></i>
              </button>
              <Collapse in={openPracticeCollapse}>
                <div className="collapse " id="financial-collapse">
                  <ul className="btn-toggle-nav ">
                    <li
                      className={`${
                        location.pathname === "/managepractice" ? "active" : ""
                      } `}
                    >
                      <Link
                        to="/managepractice"
                        className="link-dark rounded"
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Manage Practice area
                      </Link>
                    </li>
                    <li
                      className={`${
                        location.pathname === "/addpractice" ? "active" : ""
                      } `}
                    >
                      <Link
                        to="/addpractice"
                        className="link-dark rounded"
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Add new Practice area
                      </Link>
                    </li>
                    <li
                      className={`${
                        location.pathname === "/managecategories"
                          ? "active"
                          : ""
                      } `}
                    >
                      <Link
                        to="/managecategories"
                        className="link-dark rounded"
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Manage Categories
                      </Link>
                    </li>
                    <li
                      className={`${
                        location.pathname === "/addcategories" ? "active" : ""
                      } `}
                    >
                      <Link
                        to="/addcategories"
                        className="link-dark rounded"
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Add new Categories
                      </Link>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </li>
            <li
              className={`${
                location.pathname === "/QandAns" ||
                location.pathname === "/AddQandAns"
                  ? "active"
                  : ""
              }`}
            >
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                onClick={() => {
                  setOpenQuestionCollapse(!openQuestionCollapse);
                }}
                // data-bs-toggle="collapse"
                // data-bs-target="#QandA-collapse"
                aria-expanded={openQuestionCollapse}
              >
                Manage frequently Questions
                <i className="fa fa-caret-down"></i>
              </button>
              <Collapse in={openQuestionCollapse}>
                <div className="collapse " id="QandA-collapse">
                  <ul className="btn-toggle-nav">
                    <li
                      className={`${
                        location.pathname === "/QandAns" ? "active" : ""
                      }`}
                    >
                      <Link
                        to="/QandAns"
                        className="link-dark rounded"
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Manage frequently Questions
                      </Link>
                    </li>
                    <li
                      className={`${
                        location.pathname === "/AddQandAns" ? "active" : ""
                      }`}
                    >
                      <Link
                        to="/AddQandAns"
                        className="link-dark rounded"
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Add new frequently Questions
                      </Link>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </li>
            <li
              className={`${
                location.pathname === "/testimonies" ||
                location.pathname === "/userRequests"
                  ? "active"
                  : ""
              }`}
            >
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                onClick={() => {
                  setOpenAdditionalOptions(!openAdditionalOptions);
                }}
                // data-bs-toggle="collapse"
                // data-bs-target="#QandA-collapse"
                aria-expanded={openAdditionalOptions}
              >
                Additional Options
                <i className="fa fa-caret-down"></i>
              </button>
              <Collapse in={openAdditionalOptions}>
                <div className="collapse " id="QandA-collapse">
                  <ul className="btn-toggle-nav">
                    <li
                      className={`${
                        location.pathname === "/testimonies" ? "active" : ""
                      }`}
                    >
                      <Link
                        to="/testimonies"
                        className="link-dark rounded"
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Testimonies
                      </Link>
                    </li>
                    <li
                      className={`${
                        location.pathname === "/addTestimony" ? "active" : ""
                      }`}
                    >
                      <Link
                        to="/addTestimony"
                        className="link-dark rounded"
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Add Testimonies
                      </Link>
                    </li>
                    <li
                      className={`${
                        location.pathname === "/userRequests" ? "active" : ""
                      }`}
                    >
                      <Link
                        to="/userRequests"
                        className="link-dark rounded"
                        onClick={() => setToggleButton(!toggleButton)}
                      >
                        Contact US Requests
                      </Link>
                    </li>
                    <li className={`${location.pathname === "/newsLetter" ? "active" : ""}`}>
              <Link
                to="/newsLetter"
                className={`${location.pathname === "/newsLetter" ? "active" : ""}`}
                onClick={() => setToggleButton(!toggleButton)}
              >
                
                  News Letter
               
              </Link>
            </li>
                  </ul>
                </div>
              </Collapse>
            </li> */}
             <div className="logut-sidebar-wrp">
          <Link to="/" onClick={logoutFunction}>
            Logout
          </Link>
        </div>

          </ul>
        </div>
       
      </div>
    </div>
  ) : (
    <></>
  );
  return render;
};

export default SideNavbar;
