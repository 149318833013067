import { React, useEffect, useState,useContext } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { AuthContext } from "../context/AuthContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, ModalHeader,ModalFooter,ModalBody } from "reactstrap";
import config from '../config/configg';
import { TailSpin } from "react-loader-spinner";
const BACKEND_URL = config.BACKEND_URL;

const Home = () => {
  const {isLoggedIn,setIsLoggedIn,loggedInAdminInfo,setLoggedInAdminInfo}=useContext(AuthContext);
  const [deleteModal,setDeleteModal]=useState(false);
  const history = useNavigate();
  const [getUsers, setGetUsers] = useState([]);
  const [pages, setPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(5);
  const [searchedTerm, setSearchedTerm] = useState("");
  const [search, setSearch] = useState("");
  const [userToBeDeleted,setUserToBeDeleted]=useState([]);
  const [loading,setLoading]=useState(true);
  useEffect(() => {
    if (isLoggedIn) {
      fetchAllBorhanUsers(currentPage, searchedTerm);
    } else {
      history("/login");
    }

    // eslint-disable-next-line
  }, [searchedTerm]);

  const fetchAllBorhanUsers = async (current, search) => {
    setLoading(true);
    const response = await fetch(`${BACKEND_URL}/admin/getBorhanUsers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        limit: sizePerPage,
        page: current,
        search: search,
      }),
    });
    const jsonnn = await response.json();
    console.log(jsonnn.data.list, "console the users");
    setGetUsers(jsonnn.data.list);
    // setUsersDataUnAltered(jsonnn.data.list);
    setPages(
      parseInt(jsonnn.data.count % sizePerPage) == 0
        ? parseInt(jsonnn.data.count / sizePerPage)
        : parseInt(jsonnn.data.count / sizePerPage + 1)
    );
    setLoading(false)
  };
  const onClickDeleteBorhanUserByAdmin = async (obj) => {
    const response = await fetch(
      `${BACKEND_URL}/admin/deleteBorhanUserByAdmin/${obj._id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const jsonnn = await response.json();
    //  console.log(jsonnn,"deleted borhan user is");
    if(jsonnn.statusCode===200)
    {
      toast('User has been successfully deleted');
      setDeleteModal(false);
    }
    else {
      toast(jsonnn.message);
    }
    fetchAllBorhanUsers(currentPage, searchedTerm);
  };
  const handlePageClick = (data) => {
    let current = data.selected + 1;
    // console.log(current, "currentpage");
    setCurrentPage(current);
    fetchAllBorhanUsers(current, searchedTerm);
  };
  const searchData = (e) => {
    setSearchedTerm(e.target.value);
  };
  
  return (
    <>
      {loading ? (
          <div className="custm-loader">
            <TailSpin color="#000" height={200} width={200} />
          </div>
        ) : null}
         
      <section class="admin-wrapper">
    
        <div class="admin-content-wrapper">
          <div class="admin-title-header mt-0">
            <div class="row">
              <div class="col-lg-6">
                <div class="">
                  <div class="admin-title-flex">
                    <h3>Manage Client</h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="admin-short-nav-buttons">
                  <div class="table-btn-group">
                    {/* <Link to="/">
                      <button class="btn black-fill" type="button">
                        Add Client
                      </button>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="admin-filter-navbar bg-grey">
            <div class="row align-items-center justify-content-end">
              <div class="col-lg-3">
                <div class="filter-input-box">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search User"
                    onChange={searchData}
                  />
                  <img src="./assets/img/search-icon.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-lg-12">
              <div class="admin-white-box">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="common-table-wrapper mt-0">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>User name </th>
                              <th>User Email ID </th>
                              <th>User Contact </th>
                              <th>Account Created </th>
                              <th> &nbsp; </th>
                            </tr>
                          </thead>
                          <tbody>
                            {console.log(getUsers, "getUser ki length kya hai")}
                            {getUsers &&
                              getUsers.map((obj, index) => {
                                return (
                                  <tr>
                                    <td>
                                      <img
                                        src="./assets/img/user-img.png"
                                        class="img img-fluid"
                                        alt=""
                                      />
                                    </td>
                                    <td>
                                      <h5>
                                        {obj?.userId?.firstName}{" "}
                                        {obj?.userId?.lastName}
                                      </h5>
                                    </td>
                                    <td>
                                      <h5>{obj?.userId?.email}</h5>
                                    </td>
                                    <td>
                                      <h6>{obj?.userId?.mobileNo}</h6>
                                    </td>

                                    <td>
                                      <h6>
                                        {moment(obj.createdAt).format(
                                          "MMMM Do YYYY, h:mm:ss a"
                                        )}
                                      </h6>
                                    </td>
                                    <td>
                                      <div class="action-btn-group">
                                        {/* <Link to="javascript:;"><button class="btn" type="button"> <img
                                                      src="./assets/img/eye-icon-black.png" alt="" /> </button></Link> */}
                                        {/* <Link to="javascript:;">
                                          <button class="btn" type="button">
                                            {" "}
                                            <img
                                              src="./assets/img/edit-pencil-icon.png"
                                              alt=""
                                            />{" "}
                                          </button>
                                        </Link> */}
                                        <a>
                                          <button
                                            class="btn"
                                            type="button"
                                            onClick={() => {
                                              // onClickDeleteBorhanUserByAdmin(
                                              //   obj
                                              // );
                                              setUserToBeDeleted(obj);
                                              setDeleteModal(true);
                                            }}
                                          >
                                            {" "}
                                            <img
                                              src="./assets/img/delete-icon.png"
                                              alt=""
                                            />{" "}
                                          </button>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                        {getUsers.length >0 ?
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={pages}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination justify-content-center"
                          }
                          forcePage={currentPage - 1}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                    :<div className="text-center">No Data</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer/>
      <Modal isOpen={deleteModal} toggle={() => setDeleteModal(false)} className='  modal-dialog modal-dialog-centered '>
      
  
      <ModalHeader>
       <h5 class="modal-title" id="exampleModalLabel">Borhan </h5>
       
        </ModalHeader>
      <ModalBody>
        Please Confirm to Delete User
      </ModalBody>
      <ModalFooter>
        <button type="button" class="btn btn-secondary" onClick={()=>{setDeleteModal(false);}}>Close</button>
        <button type="button" class="btn btn-primary" onClick={()=>onClickDeleteBorhanUserByAdmin(userToBeDeleted)}>Delete User</button>
      </ModalFooter>
    
     
 </Modal>
    </>
  );
};

export default Home;
